<template>
  <div
    class="MenuInfo"
    :class="{'BentoMenuInfo': isBentoMenu}">
    <two-columns
      valign="bottom"
      :leftColumnWidth="isBentoMenu ? 60 : 50">
      <template v-if="menuDetails">
        <highlight-menus-pricing v-if="menuDetails.type === 'HighlightMenu'"/>
        <menu-pricing
          v-else
          :title="menuDetails.type === 'SeminarMenu' ? 'Seminar Menu' : menuDetails.name"
          titleTag="h1"
          :menuConfigs="menuDetails.menuConfigs"/>
      </template>
      <template slot="right">
        <fulfilment-info
          v-if="!isCnyAlaCarteMenu && !isChristmasAlaCarteMenu"
          :earliestTimeLabel="earliestTimeLabel"
          :latestTimeLabel="latestTimeLabel"
          :availableTimeRanges="availableTimeRanges"
          :showSetupAndTeardownTime="setupAndTeardownTimeVisible"/>
        <div
          v-else-if="isCnyAlaCarteMenu"
          class="CnyAlaCarteFulfilmentInfo"
          v-html="cnyAlaCarteMenu.terms"/>
        <div
          v-else-if="isChristmasAlaCarteMenu"
          class="ChristmasAlaCarteFulfilmentInfo"
          v-html="christmasAlaCarteMenu.terms"/>
      </template>
    </two-columns>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EARLIEST_TIME, LATEST_TIME, MINI_BUFFET_LATEST_TIME, OVERRIDE_TIME_SETTINGS, getAvailableTimeRanges, getEarliestTime, getLatestTime } from '@/assets/js/order-helper'
import { CHRISTMAS_ALA_CARTE_MENU, CNY_ALA_CARTE_MENU, isBentoMenu } from '@/assets/js/highlight-menu-helper'
import TwoColumns from '@/components/common/TwoColumns'
import HighlightMenusPricing from './HighlightMenusPricing'
import MenuPricing from '@/components/MenuPricing'
import FulfilmentInfo from './FulfilmentInfo'

export default {
  name: 'menu-info',
  components: {
    TwoColumns,
    HighlightMenusPricing,
    MenuPricing,
    FulfilmentInfo
  },
  props: {
    menuDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'isCnyAlaCarteMenu',
      'isChristmasAlaCarteMenu'
    ]),
    isBentoMenu() {
      if (this.getActiveOrder) {
        return isBentoMenu(this.getActiveOrder.menuDetails.id)
      }
      return false
    },
    earliestTimeLabel() {
      if (this.getActiveOrder && OVERRIDE_TIME_SETTINGS) {
        return this.$momenttz(
          getEarliestTime(
            this.getActiveOrder.selectedDate,
            this.getActiveOrder
          )
        ).format('h:mmA')
      }

      let time = EARLIEST_TIME
      if (this.menuType !== 'SeminarMenu' && this.selectedMenuPricing && this.selectedMenuPricing.earliestServingTime) {
        time = this.selectedMenuPricing.earliestServingTime
      }
      return this.$momenttz(time).format('h:mmA')
    },
    latestTimeLabel() {
      if (this.getActiveOrder && OVERRIDE_TIME_SETTINGS) {
        return this.$momenttz(
          getLatestTime(
            this.getActiveOrder.selectedDate,
            this.getActiveOrder.menuDetails.eventType,
            this.getActiveOrder
          )
        ).format('h:mmA')
      }

      let time = LATEST_TIME
      if (this.menuType !== 'SeminarMenu' && this.selectedMenuPricing && this.selectedMenuPricing.latestServingTime) {
        time = this.selectedMenuPricing.latestServingTime
      } else if (this.eventType === 'MiniBuffet') {
        time = MINI_BUFFET_LATEST_TIME
      }
      return this.$momenttz(time).format('h:mmA')
    },
    availableTimeRanges() {
      if (OVERRIDE_TIME_SETTINGS) {
        return getAvailableTimeRanges(this.getActiveOrder)
      }
      return []
    },
    setupAndTeardownTimeVisible() {
      if (this.isBentoMenu) return false

      return this.getActiveOrder && this.getActiveOrder.menuDetails.eventType !== 'MiniBuffet'
    },
    selectedMenuPricing() {
      return this.getActiveOrder && this.getActiveOrder.selectedMenuPricing
    },
    menuType() {
      return this.getActiveOrder && this.getActiveOrder.menuDetails.type
    },
    eventType() {
      return this.getActiveOrder && this.getActiveOrder.menuDetails.eventType
    },
    cnyAlaCarteMenu() {
      return CNY_ALA_CARTE_MENU
    },
    christmasAlaCarteMenu() {
      return CHRISTMAS_ALA_CARTE_MENU
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";

@import "~@/assets/css/_shared_variables.sass";

.MenuInfo {
  &:not(.BentoMenuInfo) /deep/ > .TwoColumns > .GridColumn {
    @media #{$tablet-up} and (max-width: 1000px) {
      width: 50% !important;
      margin-bottom: $space-m;
      text-align: left;
    }
  }

  &.BentoMenuInfo /deep/ > .TwoColumns > .GridColumn {
    &:first-child {
      @media #{$tablet-up} and (max-width: 1000px) {
        width: 75% !important;
      }
    }

    &:last-child {
      @media #{$tablet-up} and (max-width: 1000px) {
        width: 25% !important;
      }

      > .GridRow > .GridColumn {
        width: 50% !important;
        max-width: 150px;
      }
    }
  }

  /deep/ {
    > .TwoColumns > .GridColumn {
      &:last-child:not(:first-child) {
        text-align: right;
      }

      @media #{$tablet-down} {
        &:last-child:not(:first-child) {
          text-align: left;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .FulfilmentInfo {
      color: $gray-lighter;
    }

    .CnyAlaCarteFulfilmentInfo,
    .ChristmasAlaCarteFulfilmentInfo {
      @extend %body;
      @extend %bold_weight;
      color: $gray-lighter;

      > span {
        white-space: nowrap;
      }
    }

    .MenuTitle,
    .MenuTitle h1,
    .MenuConfigPrice,
    .MenuConfigPrice .Price,
    .MenuConfigPrice .PerGuest,
    .MenuConfigDetails {
      color: $white;
    }

    .MenuTitle {
      &,
      h1 {
        @extend %display_large;

        @media only screen and #{$tablet-down} {
          margin-top: $space-l;
        }
      }
    }
  }
}
</style>
