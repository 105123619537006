<template>
  <date-picker
    class="EventDatePicker"
    :selectedDate="selectedDate"
    :minDate="getMinDate"
    :maxDate="getMaxDate"
    :disabledDates="disabledDates"
    :placeholder="$t('eventDatePicker.placeholder')"
    :error="error"
    v-on="$listeners"/>
</template>

<script>
import { mapGetters } from 'vuex'
import { getSameTimeFromUTC, getSameTimeInCurrentZone, getSameTimeInUTC } from '@/assets/js/datetime'
import { getMaxDate, getMinDate, isDateSelectedValid } from '@/assets/js/order-helper'
import DatePicker from '@/components/common/DatePicker'

export default {
  name: 'event-date-picker',
  components: {
    DatePicker
  },
  props: {
    selectedDate: {
      type: String,
      default: null
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getCheckoutErrorsVisible',
      'getDisabledDates',
      'getMenuDisabledDates'
    ]),
    isCateringMenuPage() {
      return this.$route.name === 'catering-menu'
    },
    error() {
      if (this.getCheckoutErrorsVisible && !this.selectedDate) {
        return this.$t('eventDatePicker.emptyError')
      }
      if (this.selectedDate && this.isCateringMenuPage && this.getActiveOrder && !isDateSelectedValid(this.getActiveOrder)) {
        return this.$t('eventDatePicker.invalidError')
      }
      return null
    },
    getMinDate() {
      const minDate = getMinDate(this.isCateringMenuPage ? this.getActiveOrder : null)
      if (this.minDate) {
        if (this.$momenttz(this.minDate) > minDate) {
          return getSameTimeInUTC(this.minDate).toISOString()
        }
      }
      return minDate ? getSameTimeInUTC(minDate).toISOString() : minDate
    },
    getMaxDate() {
      const maxDate = getMaxDate(this.isCateringMenuPage ? this.getActiveOrder : null)
      if (this.maxDate) {
        return getSameTimeInUTC(this.maxDate).toISOString()
      }
      return maxDate ? getSameTimeInUTC(maxDate).toISOString() : maxDate
    },
    disabledDates() {
      if (this.isCateringMenuPage) {
        return this.getDisabledDates.concat(this.getMenuDisabledDates)
      }
      return this.getDisabledDates
    }
  },
  watch: {
    disabledDates() {
      this.checkSelectedDateValidity()
    }
  },
  created() {
    this.checkSelectedDateValidity()
  },
  methods: {
    checkSelectedDateValidity() {
      if (this.selectedDate && getSameTimeInCurrentZone(this.selectedDate) < getSameTimeFromUTC(this.getMinDate)) {
        this.$emit('change', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/menu-input";
</style>
