<template>
  <div class="NumberInput">
    <img
      v-if="showStepper"
      src="@/assets/images/icons/icon-add.svg"
      class="Icon"
      @click="increaseAmount">
    <img
      v-if="showStepper"
      src="@/assets/images/icons/icon-reduce.svg"
      class="IconTwo"
      @click="decreaseAmount">
    <div :class="{'Empty': !isNumber(amount)}">
      <input
        :type="editing ? 'number' : 'text'"
        :value="getValue"
        :readonly="!editing"
        :placeholder="editing ? '' : placeholder"
        class="HideInputSpinner"
        :class="{'Error': error}"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange">
    </div>
    <div
      v-if="error"
      class="InputErrorMessage">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'number-input',
  props: {
    selectedAmount: {
      type: Number,
      default: null
    },
    minAmount: {
      type: Number,
      default: null
    },
    maxAmount: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: 1
    },
    showStepper: {
      type: Boolean,
      default: true
    },
    allowZero: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: null
    },
    suffix: {
      type: Array,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      amount: this.selectedAmount,
      editing: false
    }
  },
  computed: {
    getValue() {
      if (this.suffix && this.suffix.length > 0) {
        if (this.editing && this.amount != null) {
          return this.amount
        }
        if (this.amount != null) {
          return `${this.amount} ${this.amount === 1 ? this.suffix[0] : this.suffix[1]}`
        }
      } else {
        return this.amount
      }
      return null
    }
  },
  watch: {
    selectedAmount() {
      this.amount = this.selectedAmount
    },
    amount() {
      this.$emit('change', this.amount != null ? parseInt(this.amount) : this.amount)
    }
  },
  created() {
    if (!this.editable) {
      this.amount = this.minAmount
    }
  },
  methods: {
    isNumber(value) {
      return !Number.isNaN(parseFloat(value))
    },
    autoAdjustAmount() {
      if (this.allowZero && (!this.isNumber(this.amount) || (this.amount < 0))) {
        this.amount = 0
        return true
      } else if (!this.allowZero && (!this.isNumber(this.amount) || (this.isNumber(this.minAmount) && this.amount < this.minAmount))) {
        this.amount = this.minAmount
        return true
      } else if (this.isNumber(this.maxAmount) && this.amount > this.maxAmount) {
        this.amount = this.maxAmount
        return true
      }
      return false
    },
    decreaseAmount() {
      if (!this.autoAdjustAmount()) {
        if (!this.isNumber(this.minAmount) || (this.amount > this.minAmount)) {
          if (this.amount % this.step !== 0) {
            this.amount -= this.amount % this.step
          } else {
            this.amount -= this.step
          }
        } else if (this.allowZero && this.amount > 0) {
          this.amount = 0
        }
      }
    },
    increaseAmount() {
      if (!this.autoAdjustAmount()) {
        if (this.amount < this.minAmount) {
          this.amount = this.minAmount
        } else if (!this.isNumber(this.maxAmount) || (this.amount < this.maxAmount)) {
          if (this.amount % this.step !== 0) {
            this.amount += this.step - (this.amount % this.step)
          } else {
            this.amount += this.step
          }
        }
      }
    },
    onFocus(event) {
      if (this.editable) {
        this.editing = true
        this.$nextTick(() => event.target.select())
      }
    },
    onBlur() {
      if (this.editable) {
        this.editing = false
      }
    },
    onChange(event) {
      this.amount = event.target.value != null ? parseInt(event.target.value) : null
      if (this.isNumber(this.minAmount) && this.amount < this.minAmount) {
        this.amount = this.minAmount
      }
      if (this.isNumber(this.maxAmount) && this.amount > this.maxAmount) {
        this.amount = this.maxAmount
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.NumberInput {
  position: relative;

  .Icon, .IconLeft {
    position: absolute;
    padding: 0.75rem 1rem;
    cursor: pointer;
  }
  .Icon {
    right: 0;
  }
  .IconLeft {
    left: 0;
  }
  input[type="text"], input[type="number"] {
    box-sizing: border-box;
    width: 100%;
    padding: $space-s $space-xl;
    font-family: $font-family;
    text-align: center;
    cursor: pointer;
  }
}
</style>
