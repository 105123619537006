<template>
  <base-page
    shoutType="catering"
    class="MenuPage">
    <template slot="header">
      <page-header
        :bannerSrc="getMenuCover"
        :bannerMinHeight="(menuDetails && menuDetails.type === 'HighlightMenu') ? 376 : 300"
        :bannerNoGradient="false"
        :bannerForMenu="true">
        <menu-info
          v-if="menuDetails"
          :menuDetails="menuDetails"/>
      </page-header>
    </template>

    <div class="Container">
      <div class="ProgressTrackerWrapper">
        <progress-tracker :currentStep="2"/>
      </div>
      <div v-if="loadingMenu || loadingAddOns">
        Loading...
      </div>
      <template v-if="menuDetails && !loadingAddOns">
        <side-panel
          v-for="i in 2"
          :key="`SidePanel_${i}`"
          :menuDetails="menuDetails"
          :savedOrders="getOrders"
          :activeOrderId="getActiveOrderId"
          :floating="i === 2"
          @changeOrderSettings="changeOrderSettings"
          @selectOrder="selectOrder"
          @deleteOrder="deleteOrder"
          @addOrder="addOrder"
          @checkout="checkout"/>
      </template>
      <menu-section-card-list
        v-if="!loadingMenu && menuSections && getActiveOrder"
        :menuSections="menuSections"
        :categoriesCountMapper="categoriesCountMapper"
        :savedOrderMenuSections="getActiveOrder.menuSections"
        @change="onChangeMenuSectionMeals"/>
    </div>

    <template slot="footer">
      <page-footer/>
    </template>
  </base-page>
</template>

<script>
import MenuApi from '@/api/MenuApi'
import { mapGetters } from 'vuex'
import { scrollWindowToElement } from '@/assets/js/common'
import {
  getCategoriesCountMapper,
  getDeliveryFee,
  getOrderMenuDetailsClone,
  getOrderMenuSectionsClone,
  isAddOnMenuValid,
  isOrderValid
} from '@/assets/js/order-helper'
import { ADD_ORDER, DELETE_ORDER, RESET_ORDER_STATE, SET_ACTIVE_ORDER_ID, SET_ADD_ON_MENUS, SET_CHECKOUT_ERRORS_VISIBLE, SET_HIGHLIGHT_MENUS, SET_IS_DISPOSABLES_REQUIRED, SET_MENU_COVER, SET_MENU_ID, SET_ORDER_PROP, SET_READY_FOR_CHECKOUT, SET_SELECTED_MENU, SET_SEMINAR_MENUS, UPDATE_ORDER_MENU_SECTION_MEALS } from '../actions'
import { CHRISTMAS_ALA_CARTE_MENU, CNY_ALA_CARTE_MENU, getFestiveSurcharge, isBentoMenu, isSameMenuGroup } from '@/assets/js/highlight-menu-helper'
import BasePage from '@/components/common/BasePage'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import MenuInfo from '@/components/MenuInfo'
import ProgressTracker from '@/components/ProgressTracker'
import SidePanel from '@/components/SidePanel'
import MenuSectionCardList from '@/components/MenuSectionCardList'

export default {
  name: 'menu-page',
  components: {
    BasePage,
    PageHeader,
    PageFooter,
    MenuInfo,
    ProgressTracker,
    SidePanel,
    MenuSectionCardList
  },
  props: {
    menuId: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    const title = this.menuDetails ? `Grain | Buffet Catering - ${this.menuDetails.name}` : 'Grain | Catering experiences in Singapore'
    const description = this.menuDetails ? this.menuDetails.byline : 'Our signature buffet experience for all events. Now available in bento boxes.'
    return {
      title,
      meta: [
        { name: 'robots', content: 'none' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          vmid: 'description',
          name: 'description',
          content: description
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description
        }
      ]
    }
  },
  data() {
    return {
      loadingMenu: false,
      loadingAddOns: false
    }
  },
  computed: {
    ...mapGetters([
      'getMenuId',
      'getMenuCover',
      'getSelectedMenu',
      'getSelectedDate',
      'getSelectedPax',
      'getOrders',
      'getActiveOrder',
      'getActiveOrderId',
      'getActiveOrderIndex',
      'getLastOrderId',
      'getAddOnMenus',
      'isCnyAlaCarteMenu',
      'isChristmasAlaCarteMenu'
    ]),
    menuDetails() {
      return this.getSelectedMenu
    },
    menuSections() {
      if (this.menuDetails && this.menuDetails.menuConfigs) {
        const menuConfig = this.getActiveOrder
          ? this.getActiveOrder.selectedMenuPricing
          : this.menuDetails.menuConfigs[0]
        return menuConfig.menuSections
      }
      return []
    },
    categoriesCountMapper() {
      return getCategoriesCountMapper(this.getActiveOrder)
    }
  },
  created() {
    if (this.$route.query['menu'] === 'christmas') {
      this.$store.dispatch(RESET_ORDER_STATE)
      this.$store.dispatch(SET_MENU_ID, null) // redirect back to catering home
      this.$store.dispatch(SET_MENU_COVER, null)
    } else if (this.$route.query['menu'] === 'cny') {
      this.$store.dispatch(RESET_ORDER_STATE)
      this.$store.dispatch(SET_MENU_ID, 20) // cny menu ID
      this.$store.dispatch(SET_MENU_COVER, null)
    }

    if (!this.getMenuId && !this.menuId) {
      this.$router.replace('/catering')
    } else if (this.isCnyAlaCarteMenu || this.isChristmasAlaCarteMenu) {
      window.scroll(0, 0)
      this.fetchAddOnMenus()
      this.$store.dispatch(SET_SELECTED_MENU, this.isCnyAlaCarteMenu ? CNY_ALA_CARTE_MENU : CHRISTMAS_ALA_CARTE_MENU)
      this.triggerProductViewed()
      this.addFirstOrder()
    } else {
      const menuPath = `/catering/select-menu-items/${this.menuId || this.getMenuId}`
      if (this.$route.path !== menuPath) {
        this.$router.replace(menuPath)
      }
      if (this.menuId && !isSameMenuGroup(this.menuId, this.getMenuId)) {
        this.$store.dispatch(RESET_ORDER_STATE)
        this.$store.dispatch(SET_MENU_ID, this.menuId || this.getMenuId)
        this.$store.dispatch(SET_MENU_COVER, null)
      }
      window.scroll(0, 0)
      this.fetchAddOnMenus()
      this.fetchMenu()
    }
  },
  methods: {
    async fetchAddOnMenus() {
      this.loadingAddOns = true
      this.$store.dispatch(SET_ADD_ON_MENUS, [])

      const result = (await MenuApi.fetchAddOnMenus())
      this.loadingAddOns = false
      this.$store.dispatch(SET_ADD_ON_MENUS, result.addOnMenus || [])
    },
    async fetchMenu() {
      const menuId = this.menuId || this.getMenuId

      this.loadingMenu = true
      this.$store.dispatch(SET_SELECTED_MENU, null)
      this.$store.dispatch(SET_SEMINAR_MENUS, [])
      this.$store.dispatch(SET_HIGHLIGHT_MENUS, [])

      const result = (await MenuApi.fetch(menuId))

      this.loadingMenu = false
      if (!result) {
        return this.$router.replace('/catering')
      }

      this.$store.dispatch(SET_SELECTED_MENU, result.menu)
      this.$store.dispatch(SET_SEMINAR_MENUS, result.seminarMenus || [])
      this.$store.dispatch(SET_HIGHLIGHT_MENUS, result.highlightMenus || [])
      if (!this.getMenuCover) {
        this.$store.dispatch(SET_MENU_COVER, result.menu.cover)
      }
      this.$nextTick(() => {
        this.triggerProductViewed()
      })
      this.addFirstOrder()
    },
    addFirstOrder() {
      this.$nextTick(() => {
        if (this.getOrders.length === 0) {
          this.addOrder(true)
        } else {
          this.$store.dispatch(SET_ACTIVE_ORDER_ID, this.getLastOrderId)
        }
      })
    },
    triggerProductViewed() {
      this.trackProductViewed(this.menuDetails.id, this.menuDetails.name, 'Select menu items')
    },
    changeOrderSettings(index, prop, value) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index,
        prop,
        value
      })
      if (prop === 'selectedMenuPricing') {
        this.$store.dispatch(SET_ORDER_PROP, {
          index,
          prop: 'menuSections',
          value: this.getOrderMenuSections(value.menuSections)
        })
      }
    },
    selectOrder(orderId) {
      if (this.getActiveOrderId !== orderId) {
        this.$store.dispatch(SET_ACTIVE_ORDER_ID, orderId)
        this.$nextTick(() => {
          const sidePanelTab = document.querySelector(`#SidePanelTab${orderId}`)
          scrollWindowToElement(sidePanelTab, -30)
        })
      }
    },
    deleteOrder(orderId) {
      this.$modal.show('common-modal', {
        title: this.$t('menu.deleteOrder.confirmationTitle'),
        message: this.$t('menu.deleteOrder.confirmationMessage', { orderId }),
        confirmLabel: this.$t('menu.deleteOrder.confirmLabel'),
        cancelLabel: this.$t('menu.deleteOrder.cancelLabel'),
        confirmCallback: () => {
          const orderIndex = this.getOrders.findIndex((order) => orderId === order.id)
          this.$store.dispatch(DELETE_ORDER, orderIndex)
          if (this.getActiveOrderId === orderId) {
            if (orderIndex < this.getOrders.length) {
              this.$store.dispatch(
                SET_ACTIVE_ORDER_ID,
                this.getOrders[orderIndex].id
              )
            } else {
              this.$store.dispatch(SET_ACTIVE_ORDER_ID, this.getLastOrderId)
            }
          }
        }
      })
    },
    addOrder(firstAdd = false) {
      this.$store.dispatch(ADD_ORDER, {
        menuDetails: this.getOrderMenuDetails(),
        menuSections: this.getOrderMenuSections(
          this.menuDetails.menuConfigs[0].menuSections
        ),
        selectedMenuPricing: this.menuDetails.menuConfigs[0],
        selectedDate: firstAdd ? this.getSelectedDate : null,
        selectedPax: firstAdd ? this.getSelectedPax : null
      })
      if (!firstAdd) {
        this.selectOrder(this.getLastOrderId)
      } else {
        this.$store.dispatch(SET_ACTIVE_ORDER_ID, this.getLastOrderId)
      }
    },
    getOrderMenuDetails() {
      return getOrderMenuDetailsClone(this.menuDetails)
    },
    getOrderMenuSections(menuSections) {
      return getOrderMenuSectionsClone(menuSections)
    },
    checkout() {
      let validOrder = true
      this.getOrders.forEach((order, index) => {
        this.$store.dispatch(SET_ORDER_PROP, {
          index: index,
          prop: 'waiveDeliveryFee',
          value: getDeliveryFee(order) === 0
        })
        const festiveSurcharge = getFestiveSurcharge(order)
        let multiplier = order.selectedPax
        if (festiveSurcharge && isBentoMenu(order.menuDetails.id)) {
          multiplier = order.selectedAddOns.reduce((total, selectedAddOn) => total + selectedAddOn.quantity, 0)
        }
        this.$store.dispatch(SET_ORDER_PROP, {
          index: index,
          prop: 'surcharges',
          value: festiveSurcharge ? [
            {
              name: this.$t('highlightMenus.festiveSurchargeLabel', { festiveSurcharge }),
              value: festiveSurcharge * multiplier
            }
          ] : []
        })
        if (!isOrderValid(order)) {
          validOrder = false
        }
        if (!isAddOnMenuValid(order, this.getAddOnMenus)) {
          validOrder = false
        }
      })
      if (validOrder) {
        this.$store.dispatch(SET_CHECKOUT_ERRORS_VISIBLE, false)
        this.$store.dispatch(SET_READY_FOR_CHECKOUT, true)
        this.$store.dispatch(SET_IS_DISPOSABLES_REQUIRED, null) // reset disposables required option
        this.$router.push('/catering/checkout')
      } else {
        this.$store.dispatch(SET_CHECKOUT_ERRORS_VISIBLE, true)
        scrollWindowToElement(document.querySelector('.SidePanelTabs'), -30)
      }
    },
    onChangeMenuSectionMeals(menuSectionIndex, menuSectionMeals) {
      this.$store.dispatch(UPDATE_ORDER_MENU_SECTION_MEALS, {
        index: this.getActiveOrderIndex,
        menuSectionIndex,
        value: menuSectionMeals
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_shared_variables.sass";

.MenuPage {
  /deep/ {
    .ProgressTrackerWrapper {
      padding: $space-xl 0 $space-xl;
    }
    .SidePanel,
    .MenuSectionCardList {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;
    }
    .SidePanel {
      width: 100%;
      max-width: 320px;

      @media (max-width: 767.9px) {
        max-width: none;
      }
    }
    .MenuSectionCardList {
      width: calc(100% - 320px);
      padding-left: 1.5rem;

      @media (max-width: 767.9px) {
        width: 100%;
        padding-left: 0;
        margin-top: $space-l;
      }
    }
  }
}
</style>
