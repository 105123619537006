<template>
  <div class="MenuLegend">
    <div class="MenuLegendItem">
      <img src="@/assets/images/icons/icon-vegetarian.svg">
      <span>Vegetarian</span>
    </div>
    <div class="MenuLegendItem">
      <img src="@/assets/images/icons/icon-vegan.svg">
      <span>Vegan</span>
    </div>
    <div class="MenuLegendItem">
      <img src="@/assets/images/icons/icon-spicy.svg">
      <span>Spicy</span>
    </div>
    <div class="MenuLegendItem">
      <img src="@/assets/images/icons/icon-chef-hat.svg">
      <span>Chef's recommendation</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'menu-legend'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
.MenuLegend {
  text-align: right;

  .MenuLegendItem {
    display: inline-block;
    font-size: 12px;
    color: $ink;

    img {
      height: 18px;
      margin-bottom: -3px;
      margin-right: 0.25rem;
    }
  }
  .MenuLegendItem + .MenuLegendItem {
    margin-left: 1.5rem;
  }
}
</style>
