<template>
  <div class="DatePicker">
    <img
      src="@/assets/images/icons/icon-calendar.svg"
      class="IconLeft">
    <img
      src="@/assets/images/icons/icon-chevron-right.svg"
      class="Icon">
    <datetime
      v-model="date"
      type="date"
      format="MMM dd, yyyy"
      zone="UTC+8"
      valueZone="UTC+8"
      :placeholder="placeholder"
      :minDatetime="minDate"
      :maxDatetime="maxDate"
      :disabledDates="disabledDates"
      :inputClass="inputClass"
      :phrases="{'ok': 'OK', 'cancel': 'Cancel'}"
      :class="{
        'Empty': !date,
        'Error': error
      }"
      @open="onOpen"
      @close="onClose"/>
    <div
      v-if="error"
      class="InputErrorMessage">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'date-picker',
  props: {
    selectedDate: {
      type: String,
      default: null
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    disabledDates: {
      type: Array,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      date: this.selectedDate
    }
  },
  watch: {
    selectedDate() {
      this.date = this.selectedDate
    },
    date() {
      this.$emit('change', this.date)
    }
  },
  methods: {
    onOpen() {
      const body = document.querySelector('body')
      if (body) body.classList.add('datepicker-opened')
    },
    onClose() {
      const body = document.querySelector('body')
      if (body) body.classList.remove('datepicker-opened')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.DatePicker {
  position: relative;

  .Icon, .IconLeft {
    position: absolute;
    padding: 0.75rem 0.5rem;
    pointer-events: none;
  }
  .Icon {
    right: 0;
  }
  .IconLeft {
    left: 0;
  }
  /deep/ .vdatetime-input {
    box-sizing: border-box;
    width: 100%;
    font-family: $font-family;
    padding-left: 2.125rem;
    padding-right: 2.125rem;
    text-align: left;
    cursor: pointer;
  }
}
</style>
