<template>
  <div class="EventPaxInput">
    <img
      src="@/assets/images/icons/icon-guest.svg"
      class="IconLeft">
    <number-input
      :selectedAmount="selectedPax"
      :minAmount="minimumPax"
      :step="numberStep"
      :showStepper="!fixedPax"
      :editable="!fixedPax"
      :placeholder="$t('eventPaxInput.placeholder')"
      :suffix="['guest', 'guests']"
      :error="error"
      v-on="$listeners"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NumberInput from '@/components/common/NumberInput'

export default {
  name: 'event-pax-input',
  components: {
    NumberInput
  },
  props: {
    selectedPax: {
      type: Number,
      default: null
    },
    minimumPax: {
      type: Number,
      default: 10
    },
    fixedPax: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getCheckoutErrorsVisible',
      'getMenuId'
    ]),
    numberStep() {
      return 5
    },
    error() {
      if (this.selectedPax != null) {
        if (this.selectedPax < this.minimumPax) {
          return this.$t('eventPaxInput.minimumPaxError', { minimumPax: this.minimumPax })
        }
        if (this.selectedPax % this.numberStep !== 0) {
          return this.$t(`eventPaxInput.${this.numberStep === 2 ? 'multipleOfTwoError' : 'multipleOfFiveError'}`)
        }
      } else if (this.getCheckoutErrorsVisible && this.selectedPax == null) {
        return this.$t('eventPaxInput.emptyError')
      }
      return null
    }
  },
  watch: {
    fixedPax() {
      if (this.fixedPax) {
        this.$emit('change', this.minimumPax)
      }
    },
    minimumPax() {
      if (this.fixedPax) {
        this.$emit('change', this.minimumPax)
      }
    }
  },
  created() {
    if (this.fixedPax) {
      this.$emit('change', this.minimumPax)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/menu-input";
</style>
