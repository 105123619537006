<template>
  <div class="EventDetailsForm">
    <div class="EventDetailsSection">
      <event-date-picker
        :selectedDate="selectedDate"
        :minDate="minDate"
        :maxDate="maxDate"
        @change="onChangeDate"/>
      <event-fulfilment-options
        v-if="hasPickupOption"
        :isSelfPickup="getActiveOrder.isSelfPickup"
        @change="onChangeFulfilmentOption"/>
      <event-time-picker
        v-if="!(isSeminarMenu || isBentoBundlesMenu)"
        :selectedTime="selectedTime"
        :placeholder="getActiveOrder.isSelfPickup ? $t('eventTimePicker.pickupPlaceholder') : null"
        @change="onChangeTime"/>
      <event-pax-input
        v-if="paxInputVisible"
        :selectedPax="selectedPax"
        :minimumPax="minimumPax"
        :fixedPax="selectedMenuPricing && selectedMenuPricing.isFixedPax"
        @change="onChangePax"/>
    </div>

    <div
      v-if="isSeminarMenu || isBentoBundlesMenu"
      class="EventDetailsSection">
      <seminar-menu-picker
        v-if="isSeminarMenu"
        :selectedSeminarMenu="menuDetails"
        :seminarMenus="getSeminarMenus"
        @change="onChangeSeminarMenu"/>
      <template v-if="morningBreakTimePickerVisible">
        <label class="TimePickerLabel">
          {{ morningBreakTimeLabel }}
        </label>
        <event-time-picker
          :selectedTime="selectedMorningBreakTime"
          :timeRangeType="TIME_RANGE_SERVICES.BREAKFAST"
          :placeholder="morningBreakTimeLabel"
          :useConfigTimeRange="false"
          @change="onChangeMorningBreakTime"/>
      </template>
      <label class="TimePickerLabel">
        {{ lunchTimeLabel }}
      </label>
      <event-time-picker
        :selectedTime="selectedTime"
        :timeRangeType="TIME_RANGE_SERVICES.LUNCH"
        :placeholder="lunchTimeLabel"
        @change="onChangeTime"/>
      <template v-if="afternoonBreakTimePickerVisible">
        <label class="TimePickerLabel">
          {{ afternoonBreakTimeLabel }}
        </label>
        <event-time-picker
          :selectedTime="selectedAfternoonBreakTime"
          :timeRangeType="TIME_RANGE_SERVICES.TEA"
          :placeholder="afternoonBreakTimeLabel"
          :useConfigTimeRange="false"
          @change="onChangeAfternoonBreakTime"/>
      </template>
    </div>
  </div>
</template>

<script>
import { SET_MENU_ID, SET_ORDER_PROP, SET_SELECTED_MENU } from '@/actions'
import { mapGetters } from 'vuex'
import { MENU_IDS, TIME_RANGE_SERVICES, getMaxDate, getMinDate, getMinimumPax, getOrderMenuSectionsClone, isMenuAddOnMenu } from '@/assets/js/order-helper'
import EventDatePicker from '@/components/EventDatePicker'
import EventFulfilmentOptions from './EventFulfilmentOptions'
import EventTimePicker from './EventTimePicker'
import EventPaxInput from '@/components/EventPaxInput'
import SeminarMenuPicker from './SeminarMenuPicker'

export default {
  name: 'event-details-form',
  components: {
    EventDatePicker,
    EventFulfilmentOptions,
    EventTimePicker,
    EventPaxInput,
    SeminarMenuPicker
  },
  props: {
    selectedDate: {
      type: String,
      default: null
    },
    selectedTime: {
      type: String,
      default: null
    },
    selectedPax: {
      type: Number,
      default: null
    },
    isSeminarMenu: {
      type: Boolean,
      default: false
    },
    menuDetails: {
      type: Object,
      default: null
    },
    selectedMorningBreakTime: {
      type: String,
      default: null
    },
    selectedAfternoonBreakTime: {
      type: String,
      default: null
    },
    selectedMenuPricing: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      TIME_RANGE_SERVICES,
      timeSlotsAvailabilityInterval: null,
      timeSlotsAvailability: null
    }
  },
  computed: {
    ...mapGetters([
      'getSeminarMenus',
      'getSelectedMenu',
      'getActiveOrder',
      'getActiveOrderIndex',
      'getAddOnMenus',
      'isFestiveAlaCarteMenu',
      'getDisabledDates'
    ]),
    minDate() {
      const minDate = getMinDate(this.getActiveOrder)
      return minDate ? minDate.toISOString() : null
    },
    maxDate() {
      const maxDate = getMaxDate(this.getActiveOrder)
      return maxDate ? maxDate.toISOString() : null
    },
    hasPickupOption() {
      return this.getActiveOrder.menuDetails.isPickupAllowed
    },
    paxInputVisible() {
      return !this.isFestiveAlaCarteMenu && !this.isMenuAddOnMenu
    },
    isBentoBundlesMenu() {
      return (
        this.getActiveOrder.menuDetails.id === MENU_IDS.bentoBundles ||
        this.getActiveOrder.menuDetails.name.includes('Bento Bundles')
      )
    },
    isMenuAddOnMenu() {
      return isMenuAddOnMenu(this.getActiveOrder, this.getAddOnMenus)
    },
    minimumPax() {
      return getMinimumPax(this.getActiveOrder)
    },
    morningBreakTimePickerVisible() {
      if (this.isBentoBundlesMenu) {
        if (this.getActiveOrder.selectedAddOns.length === 0) return true

        return this.getActiveOrder.selectedAddOns.some(addOn => {
          return addOn.item.name.includes('Morning') || addOn.item.name.includes('Full Day')
        })
      }
      return this.morningBreakTimeLabel.indexOf('Lunch') === -1
    },
    afternoonBreakTimePickerVisible() {
      if (this.isBentoBundlesMenu) {
        if (this.getActiveOrder.selectedAddOns.length === 0) return true

        return this.getActiveOrder.selectedAddOns.some(addOn => {
          return addOn.item.name.includes('Afternoon') || addOn.item.name.includes('Full Day')
        })
      }
      return this.afternoonBreakTimeLabel.indexOf('Lunch') === -1
    },
    morningBreakTimeLabel() {
      if (this.isBentoBundlesMenu) return 'Breakfast time'
      return `${this.selectedMenuPricing.menuSections[0].name} time`
    },
    afternoonBreakTimeLabel() {
      if (this.isBentoBundlesMenu) return 'Tea break time'
      return `${this.selectedMenuPricing.menuSections[this.selectedMenuPricing.menuSections.length - 1].name} time`
    },
    lunchTimeLabel() {
      return 'Lunch time'
    }
  },
  watch: {
    menuDetails() {
      if (this.isFestiveAlaCarteMenu || this.isMenuAddOnMenu) {
        this.onChangePax(this.minimumPax)
      }
    }
  },
  created() {
    if (this.isFestiveAlaCarteMenu) {
      this.onChangePax(this.minimumPax)
    }
  },
  methods: {
    onChangeDate(date) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'selectedDate',
        value: date
      })
    },
    onChangeFulfilmentOption(bool) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'isSelfPickup',
        value: bool
      })
    },
    onChangeTime(time) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'selectedTime',
        value: time
      })
    },
    onChangePax(pax) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'selectedPax',
        value: pax
      })
    },
    onChangeSeminarMenu(menu) {
      const selectedMenuPricingIndex = this.getSelectedMenu.menuConfigs.findIndex((menuConfig) => {
        return menuConfig.id === this.selectedMenuPricing.id
      })
      this.$store.dispatch(SET_SELECTED_MENU, menu)
      this.$store.dispatch(SET_MENU_ID, menu.id)
      const menuPricing = menu.menuConfigs[selectedMenuPricingIndex]
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'selectedMenuPricing',
        value: menuPricing
      })
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'menuDetails',
        value: menu
      })
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'menuSections',
        value: getOrderMenuSectionsClone(menuPricing.menuSections)
      })
    },
    onChangeMorningBreakTime(time) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'selectedMorningBreakTime',
        value: time
      })
    },
    onChangeAfternoonBreakTime(time) {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'selectedAfternoonBreakTime',
        value: time
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import '~@/assets/css/_shared_variables.sass';

.EventDetailsForm {
  .EventDetailsSection {
    &:not(:last-child) {
      margin-bottom: $space-l;
    }

    .TimePickerLabel {
      display: block;
      margin-bottom: 0.25rem;
      @extend %body;
      color: $gray-darker;
    }
  }
}
</style>
