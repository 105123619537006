<template>
  <card
    v-if="isAddOnMenuVisible"
    :key="addOnMenu.name"
    class="AddOnMenu"
    :class="{'error': addOnMenuError}">
    <div class="AddOnsHeader">
      <span class="AddOnsTitle">
        {{ addOnMenu.name }}
      </span>
      <div
        v-if="addOnMenuByline"
        class="AddOnsByline"
        v-html="addOnMenuByline"/>
      <div
        v-if="addOnMenuError"
        class="AddOnsErrorMessage"
        v-html="addOnMenuError"/>
    </div>
    <grid-row
      v-for="(array, addOnsSetIndex) in remapAddOnMenu(addOnMenu.addOns)"
      :key="`${addOnMenu.name}-set-${addOnsSetIndex}`">
      <grid-column
        v-for="addOn in array"
        :key="`${addOnMenu.name}-set-${addOnsSetIndex}-${addOn.itemType}-${addOn.itemId}`"
        :width="hasAddOns(addOn) ? 100 : 50">
        <add-on
          :addOn="addOn"
          :addOnMenu="addOnMenu"
          :isAvailable="isAddOnAvailable(addOn)"
          :isMenuAvailable="isAddOnMenuAvailable"
          :selectedAddOns="selectedAddOns"
          @change="onChangeAddOn"/>
      </grid-column>
    </grid-row>
  </card>
</template>

<script>
import {
  addOnMenuEndTime,
  addOnMenuMaxSelection,
  addOnMenuMinSelection,
  addOnMenuStartTime,
  isAddOnAvailable,
  isAddOnMenuAvailable,
  isAddOnMenuSelectable,
  isAddOnMenuVisible
} from '@/assets/js/add-ons-helper'
import { isMinimumPaxAddOnMenuValid, isSelectableAddOnMenuValid } from '@/assets/js/order-helper'
import AddOn from './AddOn'
import Card from '@/components/common/Card'
import GridColumn from '@/components/common/GridColumn'
import GridRow from '@/components/common/GridRow'
import { mapGetters } from 'vuex'

export default {
  name: 'add-on-menu',
  components: {
    Card,
    GridRow,
    GridColumn,
    AddOn
  },
  props: {
    addOnMenu: {
      type: Object,
      required: true
    },
    selectedAddOns: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getCheckoutErrorsVisible'
    ]),
    activeOrderEventType() {
      if (this.getActiveOrder) {
        return this.getActiveOrder.menuDetails.eventType
      }
      return null
    },
    menuId() {
      if (this.getActiveOrder) {
        return this.getActiveOrder.menuDetails.id
      }
      return null
    },
    selectedDate() {
      if (this.getActiveOrder.selectedDate) {
        return this.$momenttz(this.getActiveOrder.selectedDate).startOf('day').toDate()
      }
      return null
    },
    addOnMenuSelectedQuantity() {
      const addOnMenuAddOnIds = this.addOnMenu.addOns.map((addOn) => addOn.id)
      return this.selectedAddOns
        .filter((selectedAddOn) => addOnMenuAddOnIds.includes(selectedAddOn.id))
        .reduce((total, selectedAddOn) => total + (selectedAddOn.quantity || 0), 0)
    },
    minSelection() {
      return addOnMenuMinSelection(this.addOnMenu)
    },
    maxSelection() {
      return addOnMenuMaxSelection(this.addOnMenu)
    },
    addOnMenuError() {
      if (this.getCheckoutErrorsVisible) {
        if (!isMinimumPaxAddOnMenuValid(this.addOnMenu, this.getActiveOrder)) {
          return this.$t('addOns.minimumOrder', { minimum: this.getActiveOrder.selectedMenuPricing.minimumPax })
        }
        if (!isSelectableAddOnMenuValid(this.addOnMenu, this.getActiveOrder)) {
          return this.$tc('addOns.minSelectionError', this.minSelection, { count: this.minSelection })
        }
      }
      if (isAddOnMenuSelectable(this.addOnMenu) && this.addOnMenuSelectedQuantity > this.maxSelection) {
        return this.$tc('addOns.maxSelectionError', this.maxSelection, { count: this.maxSelection })
      }
      return null
    },
    addOnMenuByline() {
      if (this.addOnMenu.byline) {
        return this.addOnMenu.byline
      }

      const selectableStartTime = addOnMenuStartTime(this.addOnMenu)
      const selectableEndTime = addOnMenuEndTime(this.addOnMenu)
      if (selectableStartTime && selectableEndTime) {
        return this.$t('addOns.availableStartEndTime', { startTime: selectableStartTime.format('h:mmA'), endTime: selectableEndTime.format('h:mmA') })
      } else if (selectableStartTime) {
        return this.$t('addOns.availableStartTime', { startTime: selectableStartTime.format('h:mmA') })
      } else if (selectableEndTime) {
        return this.$t('addOns.availableEndTime', { startTime: selectableStartTime.format('h:mmA') })
      }

      return null
    },
    isAddOnMenuVisible() {
      return isAddOnMenuVisible(this.addOnMenu, this.menuId, this.activeOrderEventType, this.selectedDate)
    },
    isAddOnMenuAvailable() {
      return isAddOnMenuAvailable(this.addOnMenu, this.getActiveOrder.selectedTime, this.activeOrderEventType, this.selectedDate)
    }
  },
  watch: {
    isAddOnMenuVisible() {
      this.$emit('availableChange')
    },
    isAddOnMenuAvailable() {
      this.$emit('availableChange')
    }
  },
  methods: {
    remapAddOnMenu(addOns) {
      const remapped = []
      let addOnGroup = []
      // group add-ons into rows of 2 columns
      // except add-on with add-ons, which should take up a whole row by itself
      addOns.forEach((addOn) => {
        if (this.hasAddOns(addOn)) {
          if (addOnGroup.length > 0) {
            remapped.push(addOnGroup)
            addOnGroup = []
          }
          remapped.push([addOn])
          return
        }

        addOnGroup.push(addOn)
        if (addOnGroup.length === 2) {
          remapped.push(addOnGroup)
          addOnGroup = []
        }
      })

      if (addOnGroup.length > 0) {
        remapped.push(addOnGroup)
        addOnGroup = []
      }
      return remapped
    },
    hasAddOns(addOn) {
      return addOn.addOns && addOn.addOns.length > 0
    },
    isAddOnAvailable(addOn) {
      return isAddOnAvailable(addOn, this.activeOrderEventType, this.selectedDate)
    },
    onChangeAddOn(addOn, counter) {
      this.$emit('change', addOn, counter)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.AddOns {
  .AddOnMenu {
    &:not(:last-child) {
      margin-bottom: $space-m;
    }
    &.error {
      background-color: $red-lighter;
      border: 1px solid $red;
    }
    .AddOnsHeader {
      margin-bottom: $space-l;

      .AddOnsTitle {
        margin-bottom: $space-xs;
        @extend %small;
        @extend %bold_weight;
        color: $gray-dark;
        text-transform: uppercase;
      }
      .AddOnsByline{
        margin-top: $space-xxs;
        @extend %body;
      }
      .AddOnsErrorMessage {
        @extend %body;
        color: $red;
      }
    }
    /deep/ .GridRow > .GridColumn {
      margin-bottom: $space-l;
    }
  }
}
</style>
