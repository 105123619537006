<template>
  <div class="Card">
    <img
      v-if="thumbnail"
      :src="thumbnail"
      :alt="alt">
    <div class="CardContent">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    thumbnail: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.Card {
  background-color: $white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  img {
    display: block;
    width: 100%;
  }

  .CardContent {
    padding: $space-m;
  }
}
</style>
