<template>
  <div
    class="MenuPricing"
    :class="{'Selectable': selectable}">
    <div
      v-if="title"
      class="MenuTitle">
      <component :is="titleTag">
        {{ title }}
      </component>
      <div
        v-if="byline"
        class="MenuByline">
        {{ byline }}
      </div>
    </div>
    <grid-row
      :keepColumnWidth="true"
      :class="{'page-header-single-row': menuConfigs.length <= 4}">
      <grid-column
        v-for="(menuConfig, index) in menuConfigs"
        :key="`${title}-menu-config-${index}`"
        :width="getColumnWidth">
        <menu-pricing-details
          :menuConfig="menuConfig"
          :selectable="selectable"
          :selectedMenuPricing="selectedMenuPricing"
          :singleLine="singleLine"
          :showDetails="showDetails"
          :customised="customised"
          @change="onChangeMenuPricing"/>
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
import GridRow from '@/components/common/GridRow'
import GridColumn from '@/components/common/GridColumn'
import MenuPricingDetails from './MenuPricingDetails'

export default {
  name: 'menu-pricing',
  components: {
    GridRow,
    GridColumn,
    MenuPricingDetails
  },
  props: {
    title: {
      type: String,
      default: null
    },
    byline: {
      type: String,
      default: null
    },
    titleTag: {
      type: String,
      default: 'div'
    },
    menuConfigs: {
      type: Array,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectedMenuPricing: {
      type: Object,
      default: null
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    customised: {
      type: Boolean,
      default: false
    },
    columnWidth: {
      type: Number,
      default: null
    }
  },
  computed: {
    getColumnWidth() {
      if (this.singleLine || this.selectable) {
        return 100
      }
      return this.columnWidth || (this.menuConfigs.length === 1 ? 50 : 100 / 3)
    }
  },
  methods: {
    onChangeMenuPricing(menuPricing) {
      this.$emit('change', menuPricing)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";

@import "~@/assets/css/_shared_variables.sass";

.MenuPricing {
  width: 100%;
  max-width: 400px;

  @media only screen and #{$tablet-down} {
    max-width: 100%;
  }

  .MenuTitle {
    margin-bottom: $space-l;

    &,
    h2 {
      @extend %display_medium;
      color: $ink;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .MenuByline {
    margin-top: 0.25rem;
    @extend %body;
    font-weight: normal;
    color: $gray-darker;
  }

  /deep/ .GridRow > .GridColumn {
    margin-bottom: 0.5rem;
  }
}
</style>
