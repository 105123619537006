<template>
  <div
    class="BaseDropdownPicker"
    @click="onClick">
    <img
      src="@/assets/images/icons/icon-chevron-right.svg"
      class="Icon">
    <div :class="{'Empty': multiSelect ? items.length === 0 : item === null}">
      <input
        type="text"
        readonly
        :value="multiSelect ? items.join(', ') : (itemLabelProp ? item[itemLabelProp] : item)"
        :placeholder="placeholder">
    </div>
    <div
      v-if="dropdownVisible"
      class="BaseDropdown">
      <label
        v-for="(dataItem, index) in data"
        :key="`item-${index}-${dataItem}`"
        :for="`item_${index}`"
        class="BaseDropdownItem">
        <template v-if="multiSelect">
          <input
            :id="`item_${index}`"
            v-model="items"
            type="checkbox"
            class="Checkbox"
            :value="dataItem">
          <span>
            {{ dataItem }}
          </span>
        </template>
        <template v-else>
          <input
            :id="`item_${index}`"
            v-model="item"
            type="radio"
            class="Radio Hidden"
            :value="dataItem">
          <span>
            {{ itemLabelProp ? dataItem[itemLabelProp] : dataItem }}
          </span>
        </template>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-dropdown-picker',
  props: {
    data: {
      type: Array,
      required: true
    },
    selectedItem: {
      type: [String, Object],
      default: null
    },
    itemLabelProp: {
      type: String,
      default: null
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      item: this.selectedItem,
      items: this.selectedItems,
      dropdownVisible: false,
      preventClickCloseDropdown: false
    }
  },
  watch: {
    selectedItem() {
      if (!this.multiSelect) {
        this.item = this.selectedItem
      }
    },
    item() {
      if (!this.multiSelect) {
        this.$emit('change', this.item)
      }
    },
    selectedItems() {
      if (this.multiSelect) {
        this.items = this.selectedItems
      }
    },
    items() {
      if (this.multiSelect) {
        this.$emit('change', this.items)
      }
    }
  },
  created() {
    document.removeEventListener('click', this.closeDropdown)
    document.addEventListener('click', this.closeDropdown)
  },
  methods: {
    onClick(event) {
      const targetClass = (event.target.classList.value || '').split(' ')
      if (
        targetClass.indexOf('BaseDropdownItem') !== -1 ||
        targetClass.indexOf('Radio') !== -1 ||
        targetClass.indexOf('Checkbox') !== -1
      ) {
        setTimeout(() => {
          this.dropdownVisible = !!this.multiSelect
        }, 0)
      } else {
        this.dropdownVisible = !this.dropdownVisible
      }
      if (this.dropdownVisible) {
        this.preventClickCloseDropdown = true
        setTimeout(() => {
          this.preventClickCloseDropdown = false
        }, 0)
      }
    },
    closeDropdown() {
      if (!this.preventClickCloseDropdown) {
        this.dropdownVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.BaseDropdownPicker {
  position: relative;

  .Icon {
    position: absolute;
    right: 0;
    padding: 0.75rem 1rem;
    cursor: pointer;
  }
  input[type="text"] {
    box-sizing: border-box;
    width: 100%;
    padding: $space-s $space-xl;
    font-family: $font-family;
    text-align: center;
    cursor: pointer;
  }
  .BaseDropdown {
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    width: 100%;
    border: 1px solid $gray;
    border-top: 0;
    background-color: $white;

    .BaseDropdownItem {
      display: block;
      padding: 0.75rem 1rem;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: $gray-lighter;
        cursor: pointer;
      }
    }
  }
}
</style>
