<template>
  <div class="EventFulfilmentOptions">
    <label>
      <input
        v-model="isPickup"
        type="radio"
        class="Radio"
        name="is_self_pickup"
        :value="true"
        @change="onChange">
      <span>
        {{ $t('eventFulfilmentOptions.pickup') }}
      </span>
    </label>
    <label>
      <input
        v-model="isPickup"
        type="radio"
        class="Radio"
        name="is_self_pickup"
        :value="false"
        :disabled="!isDeliveryEnabled"
        @change="onChange">
      <span>
        {{ $t('eventFulfilmentOptions.delivery') }}
      </span>
    </label>
    <div
      v-if="!isDeliveryEnabled"
      class="MinimumForDelivery">
      {{ $t('eventFulfilmentOptions.minimumForDelivery') }}
    </div>
  </div>
</template>

<script>
import { getFoodTotal } from '@/assets/js/order-helper'
import { mapGetters } from 'vuex'

export default {
  name: 'event-fulfilment-options',
  props: {
    isSelfPickup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPickup: false
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'isFestiveAlaCarteMenu'
    ]),
    isDeliveryEnabled() {
      if (this.isFestiveAlaCarteMenu) {
        return this.foodTotal > 100
      }
      return true
    },
    foodTotal() {
      return parseFloat(getFoodTotal(this.getActiveOrder))
    }
  },
  watch: {
    isSelfPickup: {
      immediate: true,
      handler() {
        this.isPickup = this.isSelfPickup
      }
    },
    isDeliveryEnabled: {
      immediate: true,
      handler() {
        if (!this.isDeliveryEnabled) {
          this.isPickup = true
          this.onChange()
        }
      }
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.isPickup)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";

@import "~@/assets/css/_shared_variables.sass";

.EventFulfilmentOptions {
  label {
    display: flex;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: $space-xs;
    }

    input[type="radio"] {
      min-width: 16px;
      margin: 2px $space-xs 0 0;
    }

    span {
      @extend %body;
    }
  }

  .MinimumForDelivery {
    @extend %small;
    color: $gray-dark;
  }
}
</style>
