<template>
  <div class="FilterElement">
    <label :for="id">
      <input
        :id="id"
        v-model="filters"
        type="checkbox"
        class="Checkbox"
        name="filter"
        :value="label">
      <span>
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'filter-element',
  props: {
    label: {
      type: String,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filters: []
    }
  },
  computed: {
    id() {
      return 'filter_' + this.label.toLowerCase().replace(/\s/g, '_')
    }
  },
  watch: {
    selectedFilters() {
      this.filters = this.selectedFilters
    },
    filters() {
      if (
        JSON.stringify(this.selectedFilters) !== JSON.stringify(this.filters)
      ) {
        this.$emit('change', this.filters)
      }
    }
  },
  created() {
    this.filters = this.selectedFilters
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
.FilterElement {
  font-size: 12px;
  color: $gray-dark;

  label {
    display: block;
    cursor: pointer;
  }
  .Checkbox {
    margin-bottom: -4px;
  }
}
</style>
