<template>
  <div class="EventTimePicker">
    <img
      src="@/assets/images/icons/icon-time.svg"
      class="IconLeft">
    <img
      src="@/assets/images/icons/icon-chevron-right.svg"
      class="Icon">
    <datetime
      v-model="time"
      type="time"
      format="h:mma"
      zone="UTC+8"
      valueZone="UTC+8"
      :placeholder="placeholder || $t('eventTimePicker.placeholder')"
      use12Hour
      :minuteStep="15"
      :minDatetime="earliestTime.toISOString()"
      :maxDatetime="latestTime.toISOString()"
      :phrases="{'ok': 'OK', 'cancel': 'Cancel'}"
      :class="{
        'Empty': !time,
        'Error': error
      }"/>
    <div
      v-if="error"
      class="InputErrorMessage">
      <span v-html="error"/>
      <template v-if="!isTimeSlotAvailable && isTimeSelectedValid">
        <template v-if="previousAvailableTimeSlot && nextAvailableTimeSlot">
          {{ $t('eventTimePicker.closestTimeSlots') }}
          <span
            class="ErrorClickable"
            @click="selectTime(previousAvailableTimeSlot)">
            {{ previousAvailableTimeSlot }}
          </span>
          and
          <span
            class="ErrorClickable"
            @click="selectTime(nextAvailableTimeSlot)">
            {{ nextAvailableTimeSlot }}<!--
          -->
          </span>.
        </template>
        <template v-else-if="previousAvailableTimeSlot || nextAvailableTimeSlot">
          {{ $t('eventTimePicker.closestTimeSlot') }}
          <span
            class="ErrorClickable"
            @click="selectTime(previousAvailableTimeSlot || nextAvailableTimeSlot)">
            {{ previousAvailableTimeSlot || nextAvailableTimeSlot }}<!--
          -->
          </span>.
        </template>
        <template v-else>
          {{ $t('eventTimePicker.noAvailableTimeSlots', { selectedDate: selectedDateLabel }) }}
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BREAKFAST_TIME_RANGE,
  LUNCH_TIME_RANGE,
  OVERRIDE_TIME_SETTINGS,
  TEA_TIME_RANGE,
  TIME_RANGE_SERVICES,
  getAvailableTimeRangeServices,
  getAvailableTimeRanges,
  getEarliestTime,
  getLatestTime,
  getNextAvailableTimeSlot,
  getPreviousAvailableTimeSlot,
  isTimeSelectedValid,
  isTimeSlotsAvailable
} from '@/assets/js/order-helper'
import { mapGetters } from 'vuex'

export default {
  name: 'event-time-picker',
  props: {
    selectedTime: {
      type: String,
      default: null
    },
    timeRangeType: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    useConfigTimeRange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      time: this.selectedTime
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getCheckoutErrorsVisible'
    ]),
    error() {
      if (this.getCheckoutErrorsVisible && !this.selectedTime) {
        return this.$t('eventTimePicker.emptyError')
      }
      if (!this.isTimeSelectedValid) {
        if (this.timeRangeType) {
          return this.$t(`eventTimePicker.invalidSeminarTimeError.${this.timeRangeType}`, {
            earliestTime: this.$momenttz(this.earliestTime).format('h:mmA'),
            latestTime: this.$momenttz(this.latestTime).format('h:mmA')
          })
        }
        if (OVERRIDE_TIME_SETTINGS) {
          const availableTimeRanges = getAvailableTimeRanges(this.getActiveOrder)
          if (availableTimeRanges.length >= 2) {
            const availableTimeRangeServices = getAvailableTimeRangeServices(this.getActiveOrder)
            return this.$t('eventTimePicker.invalidTimeRangeError', {
              earliestTimeRange1: this.$momenttz(availableTimeRanges[0][0]).format('h:mmA'),
              latestTimeRange1: this.$momenttz(availableTimeRanges[0][1]).format('h:mmA'),
              serviceTimeRange1: availableTimeRangeServices[0],
              earliestTimeRange2: this.$momenttz(availableTimeRanges[1][0]).format('h:mmA'),
              latestTimeRange2: this.$momenttz(availableTimeRanges[1][1]).format('h:mmA'),
              serviceTimeRange2: availableTimeRangeServices[1]
            })
          }
        }
        return this.$t('eventTimePicker.invalidError', {
          earliestTime: this.$momenttz(this.earliestTime).format('h:mmA'),
          latestTime: this.$momenttz(this.latestTime).format('h:mmA')
        })
      }
      if (this.selectedTime && !this.isTimeSlotAvailable) {
        return this.$t('eventTimePicker.unavailableError', { selectedTime: this.selectedTimeLabel })
      }
      return null
    },
    isTimeSelectedValid() {
      if (this.timeRangeType) {
        if (!this.selectedTime) return true

        return (
          new Date(this.selectedTime) >= this.earliestTime &&
          new Date(this.selectedTime) <= this.latestTime
        )
      }
      return isTimeSelectedValid(
        this.selectedTime,
        this.getActiveOrder.selectedDate,
        this.getActiveOrder.menuDetails.eventType,
        this.useConfigTimeRange ? this.getActiveOrder : null
      )
    },
    earliestTime() {
      switch (this.timeRangeType) {
        case TIME_RANGE_SERVICES.BREAKFAST:
          return BREAKFAST_TIME_RANGE[0]
        case TIME_RANGE_SERVICES.LUNCH:
          return LUNCH_TIME_RANGE[0]
        case TIME_RANGE_SERVICES.TEA:
          return TEA_TIME_RANGE[0]
      }
      return getEarliestTime(
        this.getActiveOrder.selectedDate,
        this.useConfigTimeRange ? this.getActiveOrder : null
      )
    },
    latestTime() {
      switch (this.timeRangeType) {
        case TIME_RANGE_SERVICES.BREAKFAST:
          return BREAKFAST_TIME_RANGE[1]
        case TIME_RANGE_SERVICES.LUNCH:
          return LUNCH_TIME_RANGE[1]
        case TIME_RANGE_SERVICES.TEA:
          return TEA_TIME_RANGE[1]
      }
      return getLatestTime(
        this.getActiveOrder.selectedDate,
        this.getActiveOrder.menuDetails.eventType,
        this.useConfigTimeRange ? this.getActiveOrder : null
      )
    },
    selectedTimeLabel() {
      if (this.selectedTime) {
        return this.$momenttz(this.selectedTime).format('h:mmA')
      }
      return null
    },
    selectedDateLabel() {
      return this.$momenttz(this.getActiveOrder.selectedDate).format('MMM DD, YYYY')
    },
    isTimeSlotAvailable() {
      return isTimeSlotsAvailable(this.getActiveOrder, this.selectedTime)
    },
    previousAvailableTimeSlot() {
      return getPreviousAvailableTimeSlot(this.getActiveOrder, this.selectedTime, this.earliestTime)
    },
    nextAvailableTimeSlot() {
      return getNextAvailableTimeSlot(this.getActiveOrder, this.selectedTime, this.latestTime)
    }
  },
  watch: {
    selectedTime() {
      this.time = this.selectedTime
    },
    time() {
      this.$emit('change', this.time)
    }
  },
  created() {
    this.checkSelectedTimeDate()
  },
  methods: {
    checkSelectedTimeDate() {
      if (this.selectedTime) {
        const selectedTimeDate = this.$momenttz(this.selectedTime).startOf('day').toDate()
        const msDifference = new Date().setHours(0, 0, 0, 0) - selectedTimeDate.getTime()
        if (msDifference !== 0) {
          this.time = this.$momenttz(this.selectedTime).add(msDifference, 'ms').toDate().toISOString()
        }
      }
    },
    selectTime(timeLabel) {
      this.$emit('change', this.$momenttz(`${new Date().toDateString()} ${timeLabel}`, 'ddd MMM DD YYYY h:mma').toDate().toISOString())
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/menu-input";
.EventTimePicker {
  .Icon {
    pointer-events: none;
  }
  .ErrorClickable {
    cursor: pointer;
    font-weight: bold;
  }
}
</style>
