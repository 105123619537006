import PapercutApi, { PAPERCUT_API_URL } from '@/api/base/PapercutApi'

export default {
  index() {
    return PapercutApi.get(`${PAPERCUT_API_URL}/menus`)
  },
  fetch(menuId) {
    return PapercutApi.get(`${PAPERCUT_API_URL}/menus/${menuId}`)
  },
  fetchAddOnMenus() {
    return PapercutApi.get(`${PAPERCUT_API_URL}/add_on_menus`)
  }
}
