<template>
  <div class="MenuSectionCardList">
    <menu-legend/>
    <template v-if="!isFestiveAlaCarteMenu">
      <menu-section-card
        v-for="(menuSection, index) in menuSections"
        :key="`menu-section-card-${index}`"
        :menuSection="menuSection"
        :index="index"
        :categoriesCountMapper="categoriesCountMapper"
        :selectedMenuSectionMeals="orderMenuSections[index].selectedMenuSectionMeals"
        @change="onChangeMenuSectionMeals"/>
    </template>
    <add-ons/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuLegend from './MenuLegend'
import MenuSectionCard from './MenuSectionCard'
import AddOns from './AddOns'

export default {
  name: 'menu-section-card-list',
  components: {
    MenuLegend,
    MenuSectionCard,
    AddOns
  },
  props: {
    menuSections: {
      type: Array,
      required: true
    },
    categoriesCountMapper: {
      type: Object,
      required: true
    },
    savedOrderMenuSections: {
      type: Array,
      required: true
    },
    isHighlightMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orderMenuSections: []
    }
  },
  computed: {
    ...mapGetters(['isFestiveAlaCarteMenu'])
  },
  watch: {
    savedOrderMenuSections() {
      this.orderMenuSections = this.savedOrderMenuSections
    }
  },
  created() {
    this.orderMenuSections = this.savedOrderMenuSections
  },
  methods: {
    onChangeMenuSectionMeals(menuSectionIndex, menuSectionMeals) {
      this.$emit('change', menuSectionIndex, menuSectionMeals)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.MenuSectionCardList {
  /deep/ {
    .MenuLegend {
      margin-bottom: 0.5rem;

      @media only screen and #{$tablet-up} {
        margin-top: calc(-19px - 0.5rem);
      }
    }
    .MenuSectionCard:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
</style>
