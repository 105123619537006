<template>
  <number-input
    class="AddOnQuantity"
    :selectedAmount="selectedQuantity"
    :minAmount="minAmount"
    :maxAmount="maxAmount"
    :step="step"
    :allowZero="true"
    v-on="$listeners"/>
</template>

<script>
import NumberInput from '@/components/common/NumberInput'

export default {
  name: 'add-on-quantity',
  components: {
    NumberInput
  },
  props: {
    selectedQuantity: {
      type: Number,
      default: 0
    },
    minAmount: {
      type: Number,
      default: 0
    },
    maxAmount: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/menu-input";
</style>
