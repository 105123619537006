<template>
  <div class="MenuSectionMeal">
    <div class="MenuSectionMealTitle">
      <span v-html="mealName"/>
      <span
        v-if="additionalCharge"
        class="MenuSectionAdditionalCharge">
        {{ $t('common.additionalCharge', { additionalCharge }) }}
      </span>
      <img
        v-if="isVegetarian"
        src="@/assets/images/icons/icon-vegetarian.svg">
      <img
        v-if="isVegan"
        src="@/assets/images/icons/icon-vegan.svg">
      <img
        v-if="isSpicy"
        src="@/assets/images/icons/icon-spicy.svg">
      <img
        v-if="menuSectionMeal.recommended"
        src="@/assets/images/icons/icon-chef-hat.svg">
    </div>
    <div
      class="MenuSectionMealByline"
      v-html="menuSectionMeal.meal.byline"/>
  </div>
</template>

<script>
export default {
  name: 'menu-section-meal',
  props: {
    menuSectionMeal: {
      type: Object,
      required: true
    }
  },
  computed: {
    mealName() {
      return this.menuSectionMeal.meal.name.replace(/\s\(V\)/g, '').replace(/\s\(Vg\)/g, '')
    },
    isVegetarian() {
      return this.menuSectionMeal.meal.name.includes('(V)')
    },
    isVegan() {
      return this.menuSectionMeal.meal.name.includes('(Vg)')
    },
    isSpicy() {
      return (/spicy/i).test(this.menuSectionMeal.meal.tags)
    },
    additionalCharge() {
      if (this.menuSectionMeal.additionalChargePerPax) {
        return parseFloat(this.menuSectionMeal.additionalChargePerPax).toFixed(2)
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.MenuSectionMeal {
  .MenuSectionMealTitle {
    @extend %bold_weight;

    img {
      height: 18px;
      margin-left: 2px;
      margin-bottom: -2px;

      & + img {
        margin-left: 4px;
      }
    }
  }
  .MenuSectionAdditionalCharge {
    @extend %bold_weight;
  }
  .MenuSectionMealByline {
    margin-top: $space-xxs;
    font-size: 14px;
    color: $gray-dark;
  }
}
</style>
