<template>
  <div class="SeminarMenuPicker">
    <img
      src="@/assets/images/icons/icon-menu.svg"
      class="IconLeft">
    <base-dropdown-picker
      :data="seminarMenus"
      :selectedItem="selectedSeminarMenu"
      itemLabelProp="name"
      v-on="$listeners"/>
  </div>
</template>

<script>
import BaseDropdownPicker from '@/components/common/BaseDropdownPicker'

export default {
  name: 'seminar-menu-picker',
  components: {
    BaseDropdownPicker
  },
  props: {
    selectedSeminarMenu: {
      type: Object,
      required: true
    },
    seminarMenus: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/menu-input";

.SeminarMenuPicker /deep/ {
  .BaseDropdown {
    border: 0;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: $round;

    .BaseDropdownItem {
      &:hover {
        background-color: $primary;
        color: $ink;
      }
    }
  }
}
</style>
