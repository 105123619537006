<template>
  <div class="MenuSectionMealList">
    <grid-row
      v-for="(array, menuSectionMealSetIndex) in chunk(filteredMeals, 2)"
      :key="`menu-section-meal-set-${menuSectionMealSetIndex}`">
      <grid-column
        v-for="menuSectionMeal in array"
        :key="`menu-section-meal-set-${menuSectionMealSetIndex}-meal-${menuSectionMeal.mealId}`">
        <label
          :for="selectable ? `${section}_selected_meal_${menuSectionMeal.mealId}` : null"
          :class="{'MenuSectionMealWrapper': selectable}">
          <input
            v-if="selectable"
            :id="`${section}_selected_meal_${menuSectionMeal.mealId}`"
            v-model="menuSectionMeals"
            :type="isRadioSelection ? 'radio' : 'checkbox'"
            :class="isRadioSelection ? 'Radio' : 'Checkbox'"
            :name="`${section}_selected_meal`"
            :value="menuSectionMeal">
          <menu-section-meal :menuSectionMeal="menuSectionMeal"/>
        </label>
      </grid-column>
    </grid-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import chunk from 'lodash/chunk'
import { filterSelectedMenuSectionMeals } from '@/assets/js/order-helper'
import GridRow from '@/components/common/GridRow'
import GridColumn from '@/components/common/GridColumn'
import MenuSectionMeal from '@/components/MenuSectionMeal'

export default {
  name: 'menu-section-meal-list',
  components: {
    GridRow,
    GridColumn,
    MenuSectionMeal
  },
  props: {
    menuSection: {
      type: Object,
      required: true
    },
    categoriesCountMapper: {
      type: Object,
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectedMenuSectionMeals: {
      type: [Array, Object],
      default: null
    }
  },
  data() {
    return {
      menuSectionMeals: this.selectedMenuSectionMeals
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getActiveOrderIndex'
    ]),
    section() {
      return this.menuSection.name.toLowerCase().replace(/\s/g, '_')
    },
    categoryCount() {
      return this.categoriesCountMapper[this.menuSection.category]
    },
    isRadioSelection() {
      if (this.categoryCount) {
        return this.categoryCount.maxAmountRequired === 1
      } else {
        return (
          this.menuSection.minAmountRequired === 1 &&
          this.menuSection.maxAmountRequired === 1
        )
      }
    },
    filteredMeals() {
      if (!this.getActiveOrder.selectedDate) return this.menuSection.menuSectionMeals

      return filterSelectedMenuSectionMeals(this.menuSection.menuSectionMeals, this.getActiveOrder.selectedDate)
    },
    preselectedMeals() {
      if (!this.selectable) {
        return this.filteredMeals
      } else if (this.menuSection.category === null) {
        const recommendedMeals = this.filteredMeals.filter((menuSectionMeal) => menuSectionMeal.recommended)
        if (recommendedMeals.length > 0) {
          return recommendedMeals.slice(0, this.menuSection.minAmountRequired)
        } else {
          return this.filteredMeals.slice(0, this.menuSection.minAmountRequired)
        }
      } else {
        return this.isRadioSelection ? this.filteredMeals : []
      }
    },
    emptyMenuSectionMeals() {
      return !this.menuSectionMeals || (Array.isArray(this.menuSectionMeals) && this.menuSectionMeals.length === 0)
    }
  },
  watch: {
    getActiveOrderIndex(value, oldValue) {
      if (value !== oldValue) {
        this.$nextTick(this.preselectMeals)
      }
    },
    'getActiveOrder.selectedDate'() {
      this.filterSelectedMeals()
    },
    menuSection(value, oldValue) {
      if (value.id !== oldValue.id || !this.selectable) {
        this.$nextTick(this.preselectMeals)
      }
    },
    selectedMenuSectionMeals() {
      this.menuSectionMeals = this.selectedMenuSectionMeals
    },
    menuSectionMeals() {
      this.$emit('change', this.menuSectionMeals)
    }
  },
  created() {
    this.preselectMeals()
  },
  methods: {
    chunk(array, size) {
      return chunk(array, size)
    },
    preselectMeals() {
      if (this.emptyMenuSectionMeals) {
        this.menuSectionMeals = this.isRadioSelection ? this.preselectedMeals[0] : this.preselectedMeals
      }
    },
    filterSelectedMeals() {
      const filteredSelectedMeals = filterSelectedMenuSectionMeals(this.selectedMenuSectionMeals, this.getActiveOrder.selectedDate)
      this.menuSectionMeals = Array.isArray(this.selectedMenuSectionMeals) ? filteredSelectedMeals : filteredSelectedMeals[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';

.MenuSectionMealList {
  /deep/ {
    .GridRow > .GridColumn {
      margin-bottom: 1.5rem;
    }

    @media only screen and #{$tablet-down} {
      .GridRow {
        &:last-child {
          .GridColumn {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .MenuSectionMealWrapper {
      cursor: pointer;

      .Radio,
      .Checkbox {
        vertical-align: top;
        margin-top: 2px;
        margin-right: 0.5rem;
      }
      .MenuSectionMeal {
        display: inline-block;
        width: calc(100% - 1.5rem);
      }
    }
  }
}
</style>
