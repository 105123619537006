<template>
  <div class="HighlightMenusPricing">
    <h1
      class="HighlightTitle"
      v-html="title"/>
    <template v-if="!isFestiveAlaCarteMenu">
      <div
        v-for="(menuDetails, index) in sortedMenus"
        :key="`highlight-menu-pricing-${index}`"
        class="HighlightMenuPricing"
        :class="{'BentoMenuPricing': isBentoMenu}">
        <menu-pricing
          :title="menuPricingTitle(menuDetails)"
          titleTag="h2"
          :menuConfigs="menuDetails.menuConfigs"/>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CHRISTMAS_ALA_CARTE_MENU, CNY_ALA_CARTE_MENU, filterBentoMenus, filterHighlightMenus, isBentoMenu, sortedHighlightMenus } from '@/assets/js/highlight-menu-helper'
import MenuPricing from '@/components/MenuPricing'

export default {
  name: 'highlight-menus-pricing',
  components: {
    MenuPricing
  },
  computed: {
    ...mapGetters([
      'getMenuId',
      'getHighlightMenus',
      'isCnyAlaCarteMenu',
      'isChristmasAlaCarteMenu',
      'isFestiveAlaCarteMenu'
    ]),
    isBentoMenu() {
      return isBentoMenu(this.getMenuId)
    },
    title() {
      if (this.isCnyAlaCarteMenu) return CNY_ALA_CARTE_MENU.name
      if (this.isChristmasAlaCarteMenu) return CHRISTMAS_ALA_CARTE_MENU.name
      if (this.sortedMenus.length === 1) return this.sortedMenus[0].name
      if (this.isBentoMenu) return this.$t('highlightMenus.bentoMenu.title')
      return this.$t('highlightMenus.title')
    },
    sortedMenus() {
      if (this.isBentoMenu) {
        const bentoMenus = filterBentoMenus(this.getHighlightMenus)
        return sortedHighlightMenus(bentoMenus)
      }
      const filteredMenus = filterHighlightMenus(this.getHighlightMenus, this.getMenuId)
      return sortedHighlightMenus(filteredMenus)
    }
  },
  methods: {
    menuPricingTitle(menuDetails) {
      if (menuDetails.type === 'SeminarMenu') return 'Seminar Menu'
      if (this.sortedMenus.length === 1) return ''
      return menuDetails.name
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";

@import "~@/assets/css/_shared_variables.sass";

.HighlightMenusPricing {
  color: $white;

  .HighlightTitle {
    margin-top: 0;
    margin-bottom: $space-m;
    @extend %display_large;

    @media only screen and #{$tablet-down} {
      margin-top: $space-l;
      margin-bottom: 0;
    }
  }

  .HighlightMenuPricing {
    margin-right: $space-m;

    &:not(:last-child) {
      .MenuPricing {
        margin-bottom: 1.5rem;

        @media only screen and #{$tablet-down} {
          margin-bottom: 0;
        }
      }
    }

    .MenuPricing {
      /deep/ {
        .MenuTitle {
          margin-bottom: 0.25rem;

          &,
          h2 {
            @extend %display_medium;
          }

          h2 {
            margin-top: 0;
            margin-bottom: 0;
            color: $white;
          }
        }

        .Price {
          font-size: 18px;
        }
      }
    }

    @media only screen and (max-width: 1000px) {
      &:not(.BentoMenuPricing) {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        margin-right: 0;

        &:not(:last-child) {
          padding-right: $space-m;
        }

        .MenuPricing {
          display: inline-block;
          vertical-align: top;

          /deep/ {
            .GridColumn {
              &:not(:last-child) {
                margin-bottom: 1rem;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 460px) {
      &:not(:last-child) {
        padding-right: $space-xs;
      }
    }

    &.BentoMenuPricing {
      box-sizing: border-box;
      display: inline-block;
      width: 25%;
      margin-right: 0;

      &:not(:last-child) {
        padding-right: $space-m;
      }

      @media #{$small-only} {
        width: 100%;
      }
    }
  }
}
</style>
