<template>
  <div class="FilterSection">
    <div class="FilterHeader">
      Special instructions
    </div>
    <div class="FilterElements">
      <grid-row
        v-for="(array, arrayIndex) in chunk(filters, 2)"
        :key="`filter-set-${arrayIndex}`"
        :keepColumnWidth="true">
        <grid-column
          v-for="(item, index) in array"
          :key="`filter-set-${arrayIndex}-item-${index}`">
          <filter-element
            :label="item"
            :selectedFilters="selectedFilters"
            @change="onChangeFilters"/>
        </grid-column>
      </grid-row>
    </div>
  </div>
</template>

<script>
import chunk from 'lodash/chunk'
import { filters } from '@/assets/js/common'
import GridRow from '@/components/common/GridRow'
import GridColumn from '@/components/common/GridColumn'
import FilterElement from './FilterElement'

export default {
  name: 'filter-section',
  components: {
    GridRow,
    GridColumn,
    FilterElement
  },
  props: {
    selectedFilters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filters: filters
    }
  },
  methods: {
    chunk(array, size) {
      return chunk(array, size)
    },
    onChangeFilters(filters) {
      this.$emit('change', filters)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
.FilterSection {
  .FilterHeader {
    font-size: 14px;
    color: $gray-dark;
    margin-bottom: 1rem;
  }
  .FilterElements {
    margin-bottom: 2.5rem;

    /deep/ {
      .GridRow {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
