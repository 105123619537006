<template>
  <label
    class="AddOn"
    :class="{'WithCheckbox': selectable}"
    :for="selectable ? `add_on_${addOn.id}` : null">
    <input
      v-if="selectable"
      :id="`add_on_${addOn.id}`"
      :type="'checkbox'"
      :class="'Checkbox'"
      :checked="selectedQuantity > 0"
      @change="onSelectAddOn">
    <div
      class="AddOnItem"
      :class="{'WithAddOns': hasAddOns}"
      @click="showDisabledReason">
      <menu-section-meal :menuSectionMeal="addOnWithMeal"/>
      <div
        v-if="addOn.serves"
        class="AdditionalByline">
        {{ $t('addOns.servesLabel', { count: addOn.serves }) }}
      </div>
      <div
        v-if="addOn.minimumPax"
        class="AdditionalByline">
        {{ $t('menuPricingDetails.minimumPax', { count: addOn.minimumPax }) }}
      </div>
      <div
        v-if="addOn.startDate || addOn.endDate"
        class="AdditionalByline">
        {{ addOnAvailability }}
      </div>
      <grid-row
        class="AddOnPrice"
        :keepColumnWidth="true">
        <grid-column
          :width="usualPrice && !addOn.additionalFee ? 100 : 40">
          <div
            v-if="usualPrice"
            class="UsualPrice">
            <span>{{ $t('common.usualPrice', { price: formatCost(usualPrice) }) }}</span>
            <span>{{ $t(addOn.perPax ? 'common.perPax' : 'common.perItem') }}</span>
          </div>
          <span class="Price">
            {{ $t('common.price', { price: formatCost(addOn.price) }) }}
          </span>
          <span :class="addOn.perPax ? 'PerPax' : 'PerItem'">
            {{ $t(addOn.perPax ? 'common.perPax' : 'common.perItem') }}
          </span>
          <br>
          <span class="PriceWithGst">
            {{ $t('common.priceWithGst', { priceWithGst: getCostWithGst(addOn.price, selectedDate) }) }}
          </span>
        </grid-column>
        <template v-if="addOn.additionalFee">
          <grid-column
            :width="10">
            <br>+<br>
          </grid-column>
          <grid-column
            :width="50">
            <span class="Price">
              {{ $t('common.price', { price: formatCost(addOn.additionalFee) }) }}
            </span>
            <span class="PerItem">
              {{ $t('common.perItem') }}
            </span>
            <br>
            <span class="PriceWithGst">
              {{ $t('common.priceWithGst', { priceWithGst: getCostWithGst(addOn.additionalFee, selectedDate) }) }}
            </span>
          </grid-column>
        </template>
      </grid-row>
      <add-on-quantity
        v-if="!selectable"
        :selectedQuantity="selectedQuantity"
        :minAmount="addOn.minimumPax || 0"
        :maxAmount="addOnMaxAmount"
        :step="addOn.perPax ? 5 : 1"
        :class="{'Disabled': addOnDisabled}"
        @change="onChange(addOn, $event)"/>
    </div>
    <add-on-add-ons
      v-if="hasAddOns"
      :addOn="addOn"
      :isDisabled="isAddOnAddOnDisabled"
      :selectedAddOns="selectedAddOns"
      @change="onSelectAddOnAddOn"/>
  </label>
</template>

<script>
import { formatCost, scrollWindowToElement } from '@/assets/js/common'
import AddOnAddOns from './AddOnAddOns'
import AddOnQuantity from './AddOnQuantity'
import GridColumn from '@/components/common/GridColumn'
import GridRow from '@/components/common/GridRow'
import MenuSectionMeal from '@/components/MenuSectionMeal'
import { SET_CHECKOUT_ERRORS_VISIBLE } from '@/actions'
import { getCostWithGst } from '@/assets/js/order-helper'
import { isAddOnMenuSelectable } from '@/assets/js/add-ons-helper'
import { mapGetters } from 'vuex'

export default {
  name: 'add-on',
  components: {
    GridRow,
    GridColumn,
    MenuSectionMeal,
    AddOnQuantity,
    AddOnAddOns
  },
  props: {
    addOn: {
      type: Object,
      required: true
    },
    addOnMenu: {
      type: Object,
      required: true
    },
    isAvailable: {
      type: Boolean,
      required: true
    },
    isMenuAvailable: {
      type: Boolean,
      required: true
    },
    selectedAddOns: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getAddOnMenus'
    ]),
    selectedDate() {
      return this.getActiveOrder && this.getActiveOrder.selectedDate
    },
    selectable() {
      return isAddOnMenuSelectable(this.addOnMenu)
    },
    addOnMaxAmount() {
      switch (this.addOn.id) {
        // limit to max 5 for early bird price for [Black Cherry Timber Log Cake (253)] (Christmas 2023)
        case 253:
          return 5
        case 293:
          // limit promotional price Yu Sheng to 1 set per 12 pax rounded up
          return Math.ceil(this.getActiveOrder.selectedPax / 12)
      }
      return null
    },
    usualPrice() {
      const christmasAddOnMenu = this.getAddOnMenus.find((addOnMenu) => addOnMenu.id === 29)
      if (christmasAddOnMenu) {
        const christmasAddOn = christmasAddOnMenu.addOns.find((ao) => ao.itemId === this.addOn.itemId)
        if (christmasAddOn && christmasAddOn.price > this.addOn.price) {
          return christmasAddOn.price
        }
      }
      return null
    },
    addOnDisabled() {
      return !this.isMenuAvailable || !this.isAvailable
    },
    addOnWithMeal() {
      return {
        ...this.addOn,
        meal: this.addOn.item
      }
    },
    hasAddOns() {
      return this.addOn.addOns && this.addOn.addOns.length > 0
    },
    addOnAvailability() {
      let startDate
      let endDate
      if (this.addOn.startDate) {
        startDate = this.$momenttz(this.addOn.startDate).format('MMM DD')
      }
      if (this.addOn.endDate) {
        endDate = this.$momenttz(this.addOn.endDate).format('MMM DD')
      }

      if (this.addOn.startDate && this.addOn.endDate) {
        return this.$t('addOns.availableStartEndDate', { startDate, endDate })
      }
      if (this.addOn.startDate) {
        return this.$t('addOns.availableStartDate', { startDate })
      }
      if (this.addOn.endDate) {
        return this.$t('addOns.availableEndDate', { endDate })
      }
      return ''
    },
    selectedAddOn() {
      return this.selectedAddOns.find((selectedAddOn) => this.compareAddOns(selectedAddOn, this.addOn))
    },
    isSelected() {
      if (this.selectedAddOn && this.selectedAddOn.quantity > 0) {
        return true
      }
      return false
    },
    selectedQuantity() {
      if (this.selectedAddOn) {
        return this.selectedAddOn.quantity || 0
      }
      return 0
    },
    isAddOnAddOnDisabled() {
      return !this.isSelected || !this.isAvailable
    }
  },
  watch: {
    selectedQuantity() {
      if (this.hasAddOns) {
        this.addOn.addOns.forEach((addOn) => {
          if (this.selectedAddOns.find((selectedAddOn) => this.compareAddOns(selectedAddOn, addOn))) {
            this.$emit('change', addOn, this.selectedQuantity)
          }
        })
      }
    }
  },
  methods: {
    showDisabledReason() {
      if (!this.addOnDisabled) return

      if (this.isMenuAvailable) {
        return this.$modal.show('common-modal', {
          title: this.$t('addOns.unavailable')
        })
      }

      scrollWindowToElement(document.querySelector('.SidePanelTabs'), -30)
      this.$store.dispatch(SET_CHECKOUT_ERRORS_VISIBLE, true)
      this.$modal.show('common-modal', {
        title: this.$t('addOns.fillInDateModal.title'),
        message: this.$t('addOns.fillInDateModal.message')
      })
    },
    formatCost(price) {
      return formatCost(price)
    },
    getCostWithGst(price) {
      return getCostWithGst(price, this.selectedDate)
    },
    onSelectAddOn(event) {
      const newQuantity = event.target.checked ? 1 : 0
      this.onChange(this.addOn, newQuantity)
    },
    onChange(addOn, counter) {
      this.$emit('change', addOn, counter)
    },
    onSelectAddOnAddOn(addOn, checked) {
      this.$emit('change', addOn, checked ? this.selectedQuantity : 0)
    },
    compareAddOns(a, b) {
      return a.itemType === b.itemType && a.itemId === b.itemId && a.price === b.price
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.AddOn {
  &.WithCheckbox {
    display: flex;

    > input[type='checkbox'] {
      flex-shrink: 0;
      margin-top: 2px;
      margin-right: $space-xs;
    }
    .AddOnItem {
      flex-grow: 1;
    }
  }
  .AddOnItem.WithAddOns,
  .AddOnAddOns {
    @media #{$tablet-up} {
      box-sizing: border-box;
      display: inline-block;
      width: 50%;
      vertical-align: top;

      &:first-child {
        padding-right: $space-xs;
      }
      &:last-child {
        padding-left: $space-xs;
      }
    }
  }
  .AddOnItem {
    .AdditionalByline {
      margin-top: $space-xxs;
      margin-bottom: $space-xxs;
      @extend %body;
      color: $gray-dark;
    }
    .AddOnQuantity {
      max-width: 120px;
      margin-top: $space-s;

      &.Disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  /deep/ .UsualPrice {
    @extend %small;
    @extend %bold_weight;
    display: block;
    color: $orange;
  }
  /deep/ .Price {
    @extend %display_pricing;
  }
  /deep/ .PerItem, .PerPax {
    vertical-align: top;
    position: relative;
    top: $space-xxs;
    @extend %small;
    @extend %bold_weight;
    color: $ink;
  }
  /deep/ .PriceWithGst {
    @extend %small;
    @extend %bold_weight;
    display: block;
  }
}
</style>
