<template>
  <div
    class="MenuSectionCard"
    :class="{'Error': error}">
    <card>
      <div class="MenuSectionHeader">
        <span class="MenuSectionTitle">
          {{ menuSection.name }}
        </span>
        <span :class="error ? 'ErrorMessage' : 'SelectMessage'">
          {{ error || selectMessage }}
          <span
            v-if="maxAmountRequired > 1">
            ({{ selectedCount }}/{{ maxAmountRequired }})
          </span>
        </span>
      </div>
      <menu-section-meal-list
        :menuSection="menuSection"
        :categoriesCountMapper="categoriesCountMapper"
        :selectable="selectable"
        :selectedMenuSectionMeals="selectedMenuSectionMeals"
        @change="onChangeMenuSectionMeals"/>
    </card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { filterSelectedMenuSectionMeals } from '@/assets/js/order-helper'
import Card from '@/components/common/Card'
import MenuSectionMealList from '@/components/MenuSectionMealList'

export default {
  name: 'menu-section-card',
  components: {
    Card,
    MenuSectionMealList
  },
  props: {
    menuSection: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    categoriesCountMapper: {
      type: Object,
      default: null
    },
    selectedMenuSectionMeals: {
      type: [Array, Object],
      default: null
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getCheckoutErrorsVisible'
    ]),
    selectable() {
      return !(!this.menuSection.category && this.menuSection.minAmountRequired === 0 && this.menuSection.maxAmountRequired === 0)
    },
    categoryCount() {
      if (this.menuSection.category) {
        return this.categoriesCountMapper[this.menuSection.category]
      }
      return null
    },
    selectedCount() {
      if (this.categoryCount) {
        return this.categoryCount.selectedCount
      }
      return filterSelectedMenuSectionMeals(this.selectedMenuSectionMeals, this.getActiveOrder.selectedDate).length
    },
    maxAmountRequired() {
      if (this.categoryCount) {
        return this.categoryCount.maxAmountRequired
      }
      return this.menuSection.maxAmountRequired
    },
    error() {
      if (this.selectable) {
        if (this.categoryCount) {
          const categoryCount = this.categoryCount
          if (categoryCount.selectedCount > categoryCount.maxAmountRequired) {
            return this.$t('menuSectionCard.categoryCountError', { count: categoryCount.maxAmountRequired, category: this.menuSection.category })
          } else if (
            this.getCheckoutErrorsVisible &&
            categoryCount.selectedCount !== categoryCount.maxAmountRequired
          ) {
            return this.selectMessage
          }
        } else {
          if (this.selectedCount > this.menuSection.maxAmountRequired) {
            return this.$t('menuSectionCard.sectionMealsCountError', { count: this.menuSection.maxAmountRequired })
          } else if (
            this.getCheckoutErrorsVisible &&
            this.selectedCount < this.menuSection.minAmountRequired
          ) {
            return this.selectMessage
          }
        }
      }
      return null
    },
    selectMessage() {
      if (this.categoryCount) {
        return this.categoryCount.maxAmountRequired === 1
          ? this.$t('menuSectionCard.categorySelectOneMessage', { category: this.menuSection.category })
          : this.$t('menuSectionCard.categorySelectMessage', { count: this.categoryCount.maxAmountRequired, category: this.menuSection.category })
      } else if (this.menuSection.maxAmountRequired > 0) {
        if (this.menuSection.minAmountRequired < this.menuSection.maxAmountRequired) {
          return this.menuSection.maxAmountRequired === 1
            ? this.$t('menuSectionCard.sectionMealsSelectMinOneMessage')
            : this.$t('menuSectionCard.sectionMealsSelectMinMessage', { count: this.menuSection.maxAmountRequired })
        } else {
          return this.menuSection.maxAmountRequired === 1
            ? this.$t('menuSectionCard.sectionMealsSelectOneMessage')
            : this.$t('menuSectionCard.sectionMealsSelectMessage', { count: this.menuSection.maxAmountRequired })
        }
      }
      return null
    }
  },
  methods: {
    onChangeMenuSectionMeals(menuSectionMeals) {
      this.$emit('change', this.index, menuSectionMeals)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.MenuSectionCard {
  &.Error /deep/ .Card {
    background-color: $red-lighter;
    border: 1px solid $red;
  }
  /deep/ {
    .CardContent {
      padding: $space-m;
    }
  }
  .MenuSectionHeader {
    margin-bottom: $space-l;

    .MenuSectionTitle {
      margin-bottom: $space-xs;
      @extend %small;
      @extend %bold_weight;
      color: $gray-dark;
      text-transform: uppercase;
    }
    .ErrorMessage,
    .SelectMessage {
      font-size: 14px;
      font-weight: normal;
      margin-left: 0.75rem;
    }
    .ErrorMessage {
      color: $red;
    }
    .SelectMessage {
      color: $gray-dark;
    }
  }
}
</style>
