<template>
  <div
    class="SidePanel"
    :class="{
      'Floating': floating,
      'Fixed': floating && fixed
    }"
    :style="{'max-height': maxHeight}">
    <div class="SidePanelTabs">
      <template v-for="(order, index) in orders">
        <side-panel-tab
          v-if="!floating || activeOrderId === order.id"
          :key="`order-${index}`"
          :menuDetails="menuDetails"
          :order="order"
          :index="index"
          :active="activeOrderId === order.id"
          :onlyOne="orders.length <= 1"
          :floating="floating"
          @change="changeOrderSettings"
          @selectOrder="selectOrder"
          @deleteOrder="deleteOrder"/>
      </template>
    </div>
    <side-panel-footer
      v-if="!floating || orderBreakdownVisible"
      :orders="orders"
      :floating="floating"
      @addOrder="addOrder"
      @checkout="checkout"/>
  </div>
</template>

<script>
import SidePanelTab from './SidePanelTab'
import SidePanelFooter from './SidePanelFooter'

export default {
  name: 'side-panel',
  components: {
    SidePanelTab,
    SidePanelFooter
  },
  props: {
    menuDetails: {
      type: Object,
      required: true
    },
    savedOrders: {
      type: Array,
      required: true
    },
    activeOrderId: {
      type: Number,
      required: true
    },
    floating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orders: [],
      fixed: false,
      maxHeight: ''
    }
  },
  computed: {
    activeOrder() {
      return this.orders.filter((order) => order.id === this.activeOrderId)[0]
    },
    orderBreakdownVisible() {
      return this.activeOrder ? this.activeOrder.selectedPax : null
    }
  },
  watch: {
    savedOrders() {
      this.orders = this.savedOrders
    }
  },
  created() {
    this.orders = this.savedOrders
    if (this.floating) {
      this.addWatchScroll()
    }
  },
  methods: {
    addWatchScroll() {
      window.removeEventListener('scroll', this.watchScroll)
      window.addEventListener('scroll', this.watchScroll)
    },
    watchScroll() {
      const sidePanel = document.querySelector('.SidePanel:not(.Ghost)')
      this.maxHeight = ''
      if (sidePanel) {
        this.fixed = window.scrollY > sidePanel.offsetTop + sidePanel.offsetHeight
        if (this.fixed) {
          const pageBody = document.querySelector('.PageBody')
          const margins = 16
          this.maxHeight = (pageBody.offsetTop + pageBody.offsetHeight) - (window.scrollY + margins)
          this.maxHeight = Math.min(this.maxHeight, window.innerHeight - margins * 2) + 'px'
        }
      }
    },
    changeOrderSettings(index, prop, value) {
      this.$emit('changeOrderSettings', index, prop, value)
    },
    selectOrder(orderId) {
      this.$emit('selectOrder', orderId)
    },
    deleteOrder(orderId) {
      this.$emit('deleteOrder', orderId)
      window.analytics.track('Product Removed', {
        product_id: this.menuDetails.id,
        name: this.menuDetails.name
      })
    },
    addOrder() {
      this.$emit('addOrder')
      window.analytics.track('Product Added', {
        product_id: this.menuDetails.id,
        name: this.menuDetails.name
      })
    },
    checkout() {
      this.$emit('checkout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.SidePanel {
  &.Floating {
    display: none !important;
  }
  &.Fixed {
    @media #{$tablet-up} {
      display: block !important;
      width: 325px !important;
      position: fixed;
      top: 1rem;
      padding-bottom: 2px;
      overflow-x: visible;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 6px;
        background-color: rgba(0,0,0,0.05);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: rgba(0,0,0,0.25);
      }
    }
  }
  @media (max-width: 767.9px) {
    width: 100%;
  }
}
</style>
