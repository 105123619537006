<template>
  <div
    class="MenuPricingDetails"
    :class="{ SingleLine: singleLine || selectable }">
    <template
      v-if="customised">
      <div class="CustomisedContentBefore">
        <div class="MenuPricingName">
          {{ menuConfig.name }}
        </div>
        <div class="MenuPricingSummary">
          {{ menuConfig.summary }}
        </div>
        <div class="MenuPricingAdditionalSummary">
          {{ menuConfig.additionalSummary }}
        </div>
      </div>
      <div v-if="beforeEarlyBirdDate">
        <div class="MenuPriceHeader">
          Orders by Feb 1 (Thu)
        </div>
      </div>
    </template>
    <label
      :for="selectable ? `menu_config_${menuConfig.id}` : null"
      :class="{ Selectable: selectable }">
      <div :class="{ MenuConfigPriceWrapper: singleLine || selectable }">
        <input
          v-if="selectable"
          :id="`menu_config_${menuConfig.id}`"
          :checked="selectedMenuPricing.id === menuConfig.id"
          type="radio"
          class="Radio"
          name="selected_menu_config"
          :value="menuConfig"
          @change="onChangeMenuPricing(menuConfig)">
        <div class="MenuConfigPrice">
          <div
            v-if="menuConfig.pricePrefix"
            class="Price price-prefix">
            {{ menuConfig.pricePrefix }}
          </div>
          <span class="Price">
            {{ $t('common.price', { price }) }}
          </span>
          <span
            v-if="priceSuffix"
            class="PerGuest"> /{{ priceSuffix }} </span>
          <br>
          <span>
            {{ $t('common.priceWithGst', { priceWithGst }) }}
          </span>
        </div>
      </div>
      <div
        v-if="showDetails"
        class="MenuConfigDetails">
        <div class="MenuConfigSummary">
          {{ summaryCopy }}
        </div>
        <div class="MenuConfigMinimumPax">
          {{ minimumPaxCopy }}
        </div>
      </div>
      <div
        v-if="customised"
        class="CustomisedContentAfter">
        <div v-if="beforeEarlyBirdDate">
          <div class="MenuPriceHeader">Feb 2 (Fri) onwards</div>
          <div class="MenuConfigPrice">
            <div
              v-if="menuConfig.pricePrefix"
              class="Price price-prefix">
              {{ menuConfig.pricePrefix }}
            </div>
            <span class="Price">
              {{ $t('common.price', { price: standardPrice }) }}
            </span>
            <span
              v-if="priceSuffix"
              class="PerGuest"> /{{ priceSuffix }} </span>
            <br>
            <span>
              {{ $t('common.priceWithGst', { priceWithGst: standardPriceWithGst }) }}
            </span>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { getTaxRate } from '@/assets/js/order-helper'
import { mapGetters } from 'vuex'

export default {
  name: 'menu-pricing-details',
  props: {
    menuConfig: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectedMenuPricing: {
      type: Object,
      default: null
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    customised: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder'
    ]),
    priceToUse() {
      if (this.menuConfig.isFixedPax) {
        return this.menuConfig.pricePerPax * this.menuConfig.minimumPax
      }
      return this.menuConfig.pricePerPax
    },
    price() {
      return parseFloat(this.priceToUse).toFixed(2)
    },
    priceWithGst() {
      // show tax based on current day if path is /catering
      const taxRate = getTaxRate((this.$route.path !== '/catering' && this.getActiveOrder) ? this.getActiveOrder.selectedDate : null)
      return (this.priceToUse * (1 + taxRate)).toFixed(2)
    },
    priceSuffix() {
      if (this.menuConfig.isFixedPax) {
        return this.$t('menuPricingDetails.suffix.set')
      }
      if (this.menuConfig.priceSuffix !== '') {
        return this.menuConfig.priceSuffix || this.$t('menuPricingDetails.suffix.guest')
      }
      return null
    },
    standardPrice() {
      return parseFloat(this.menuConfig.standardPricePerPax).toFixed(2)
    },
    standardPriceWithGst() {
      // show tax based on current day if path is /catering
      const taxRate = getTaxRate((this.$route.path !== '/catering' && this.getActiveOrder) ? this.getActiveOrder.selectedDate : null)
      return (this.menuConfig.standardPricePerPax * (1 + taxRate)).toFixed(2)
    },
    minimumPaxCopy() {
      let count = this.menuConfig.minimumPax
      // HACK: this is a hard-coded feature
      // 48: Party Set Menu
      // 50: Chinese New Year Party Set
      // 59: Christmas Party Set Menu
      switch (this.menuConfig.menuId) {
        case 48:
        case 50:
          switch (this.menuConfig.minimumPax) {
            case 10:
              count = '8–10'
              break
            case 5:
              count = '4–6'
              break
          }
          break
        case 59:
          switch (this.menuConfig.minimumPax) {
            case 8:
              count = '6–8'
              break
            case 4:
              count = '3–5'
              break
          }
          break
      }
      return this.$t(`menuPricingDetails.${this.menuConfig.isFixedPax ? 'fixedPax' : 'minimumPax'}`, { count })
    },
    summaryCopy() {
      return this.menuConfig.summary
    },
    beforeEarlyBirdDate() {
      return this.$momenttz().startOf('day').toDate() <= new Date('1 Feb 2024')
    }
  },
  methods: {
    onChangeMenuPricing(menuPricing) {
      this.$emit('change', menuPricing)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';

@import '~@/assets/css/_shared_variables.sass';

.MenuPricingDetails {
  &.SingleLine {
    margin-bottom: 0.75rem;

    .MenuConfigPriceWrapper,
    .MenuConfigDetails {
      display: inline-block;
      width: 50%;
      vertical-align: middle;
    }

    .MenuConfigPriceWrapper {
      .Radio {
        top: 5px;
        margin-right: 0.5rem;
      }

      .MenuConfigPrice {
        display: inline-block;
        vertical-align: top;
      }
    }

    .MenuConfigDetails {
      margin-top: 0;
      text-align: right;

      .MenuConfigSummary {
        margin-bottom: 0.25rem;
      }

      .MenuConfigMinimumPax {
        color: $gray-dark;
      }
    }
  }

  .Selectable {
    cursor: pointer;
  }

  .MenuConfigPrice {
    font-size: 12px;
    @extend %bold_weight;
    color: $ink;
    white-space: nowrap;

    .Price {
      @extend %display_pricing;
    }

    .PerGuest {
      position: relative;
      top: $space-xxs;
      vertical-align: top;
    }
  }

  .MenuConfigDetails {
    margin-top: 1rem;
    font-size: 12px;
    font-weight: bold;
    color: $ink;
  }

  .MenuPricingName {
    @extend %display_medium;
    color: $ink;
  }

  .MenuPricingSummary,
  .MenuPricingAdditionalSummary {
    @extend %body;
    font-weight: normal;
    color: $gray-darker;
  }

  .MenuPricingSummary {
    margin-top: 0.25rem;
  }

  .MenuPricingAdditionalSummary {
    padding-bottom: $space-l;

    @media #{$medium-up} {
      padding-bottom: $space-xl;
    }
  }

  .MenuPriceHeader {
    @extend %body;
    @extend %bold_weight;
  }

  .CustomisedContentAfter {
    margin-top: $space-l;
  }
}

.Cny {
  .MenuPricingDetails {
    .MenuPriceHeader {
      padding-bottom: $space-m;
    }
    .MenuPricingAdditionalSummary {
      padding-bottom: $space-m !important;

      @media #{$medium-up} {
        padding-bottom: $space-l !important;
      }
    }
  }
}

</style>
