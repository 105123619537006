<template>
  <div class="AddOns">
    <add-on-menu
      v-for="addOnMenu in getAddOnMenus"
      :key="addOnMenu.name"
      :addOnMenu="addOnMenu"
      :selectedAddOns="selectedAddOns"
      @change="onChangeAddOn"
      @availableChange="onAvailableChange"/>
  </div>
</template>

<script>
import { isAddOnAvailable, isAddOnMenuAvailable, isAddOnMenuVisible } from '@/assets/js/add-ons-helper'
import AddOnMenu from './AddOnMenu'
import { SET_ORDER_PROP } from '../../../actions'
import { mapGetters } from 'vuex'

export default {
  name: 'add-ons',
  components: {
    AddOnMenu
  },
  data() {
    return {
      selectedAddOns: []
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder',
      'getActiveOrderIndex',
      'getAddOnMenus'
    ]),
    activeOrderId() {
      if (this.getActiveOrder) {
        return this.getActiveOrder.id
      }
      return null
    },
    activeOrderEventType() {
      if (this.getActiveOrder) {
        return this.getActiveOrder.menuDetails.eventType
      }
      return null
    },
    menuId() {
      if (this.getActiveOrder) {
        return this.getActiveOrder.menuDetails.id
      }
      return null
    },
    selectedDate() {
      if (this.getActiveOrder.selectedDate) {
        return this.$momenttz(this.getActiveOrder.selectedDate).startOf('day').toDate()
      }
      return null
    },
    allAddOns() {
      return this.getAddOnMenus.reduce((acc, addOnMenu) => {
        return acc.concat(addOnMenu.addOns)
      }, [])
    },
    availableAddOns() {
      return this.getAddOnMenus.reduce((acc, addOnMenu) => {
        const addOnMenuVisible = this.isAddOnMenuVisible(addOnMenu)
        const addOnMenuAvailable = this.isAddOnMenuAvailable(addOnMenu)
        if (!addOnMenuVisible || !addOnMenuAvailable) return acc

        const availableAddOns = addOnMenu.addOns.filter((addOn) => {
          return this.isAddOnAvailable(addOn)
        })
        const availableAddOnsWithNested = availableAddOns.map((addOn) => {
          if (this.hasAddOns(addOn)) {
            return [addOn].concat(addOn.addOns)
          }
          return addOn
        })
        return acc.concat(availableAddOnsWithNested.flat())
      }, [])
    },
    selectedAvailableAddOns() {
      return this.selectedAddOns.filter((addOn) => {
        return this.availableAddOns.some((availableAddOn) => this.compareAddOns(addOn, availableAddOn))
      })
    },
    sortedSelectedAddOns() {
      return this.cloneObject(this.selectedAvailableAddOns).sort((a, b) => {
        let aIndex = this.allAddOns.findIndex((addOn) => this.compareAddOns(addOn, a))
        let bIndex = this.allAddOns.findIndex((addOn) => this.compareAddOns(addOn, b))
        if (aIndex === -1) {
          aIndex += 999999
        }
        if (bIndex === -1) {
          bIndex += 999999
        }
        return aIndex - bIndex
      })
    }
  },
  watch: {
    activeOrderId() {
      this.selectedAddOns = []
      this.resetAddOnsToActiveOrder()
    },
    activeOrderEventType() {
      this.updateSelectedAddOns()
    },
    selectedDate() {
      this.updateSelectedAddOns()
    },
    selectedAddOns() {
      if (this.getAddOnMenus.length > 0) {
        this.updateSelectedAddOns()
      }
    },
    getAddOnMenus() {
      this.updateSelectedAddOns()
    }
  },
  created() {
    this.resetAddOnsToActiveOrder()
  },
  methods: {
    compareAddOns(a, b) {
      return a.itemType === b.itemType && a.itemId === b.itemId && a.price === b.price
    },
    cloneObject(object) {
      return JSON.parse(JSON.stringify(object))
    },
    resetAddOnsToActiveOrder() {
      if (this.getActiveOrder && this.getActiveOrder.selectedAddOns) {
        this.selectedAddOns = this.cloneObject(this.getActiveOrder.selectedAddOns)
      }
    },
    hasAddOns(addOn) {
      return addOn.addOns && addOn.addOns.length > 0
    },
    isAddOnMenuVisible(addOnMenu) {
      return isAddOnMenuVisible(addOnMenu, this.getActiveOrder.menuDetails.id, this.activeOrderEventType, this.selectedDate)
    },
    isAddOnMenuAvailable(addOnMenu) {
      return isAddOnMenuAvailable(addOnMenu, this.getActiveOrder.selectedTime, this.activeOrderEventType, this.selectedDate)
    },
    isAddOnAvailable(addOn) {
      return isAddOnAvailable(addOn, this.activeOrderEventType, this.selectedDate)
    },
    onChangeAddOn(addOn, counter) {
      const selectedAddOnIndex = this.selectedAddOns.findIndex((selectedAddOn) => this.compareAddOns(selectedAddOn, addOn))
      if (selectedAddOnIndex !== -1) {
        if (counter <= 0) {
          this.selectedAddOns.splice(selectedAddOnIndex, 1)
        } else {
          this.selectedAddOns[selectedAddOnIndex].quantity = counter
          this.$set(this.selectedAddOns, selectedAddOnIndex, this.selectedAddOns[selectedAddOnIndex])
        }
      } else if (counter > 0) {
        this.selectedAddOns.push({
          quantity: counter,
          ...addOn
        })
      }
    },
    onAvailableChange() {
      if (this.selectedAddOns.length > 0) {
        this.updateSelectedAddOns()
      }
    },
    updateSelectedAddOns() {
      this.$store.dispatch(SET_ORDER_PROP, {
        index: this.getActiveOrderIndex,
        prop: 'selectedAddOns',
        value: this.sortedSelectedAddOns
      })
    }
  }
}
</script>
