<template>
  <div class="AddOnAddOns">
    <div
      v-if="addOn.addOnsTitle"
      class="AddOnAddOnsTitle">
      {{ addOn.addOnsTitle }}
    </div>
    <label
      v-for="(addOnAddOn, i) in addOn.addOns"
      :key="`${addOn.itemType}_${addOn.itemId}_add_on_${i}`"
      :for="`${addOn.itemType}_${addOn.itemId}_add_on_${i}`"
      class="AddOnAddOn"
      :class="{'Disabled': isDisabled}">
      <div class="SelectAddOnAddOn">
        <input
          :id="`${addOn.itemType}_${addOn.itemId}_add_on_${i}`"
          type="checkbox"
          class="Checkbox"
          :value="`${addOnAddOn.itemType}-${addOnAddOn.itemId}`"
          :checked="isAddOnAddOnSelected(addOnAddOn)"
          :disabled="isDisabled"
          @change="onSelectAddOnAddOn(addOnAddOn, $event)">
      </div>
      <div class="AddOnAddOnMeal">
        <menu-section-meal :menuSectionMeal="addOnAddOnWithMeal(addOnAddOn)"/>
        <span class="Price">
          {{ $t('common.price', { price: addOnAddOn.price }) }}
        </span>
        <span :class="addOnAddOn.perPax ? 'PerPax' : 'PerItem'">
          {{ $t(addOnAddOn.perPax ? 'common.perPax' : 'common.perItem') }}
        </span>
        <br>
        <span class="PriceWithGst">
          {{ $t('common.priceWithGst', { priceWithGst: getCostWithGst(addOnAddOn.price, selectedDate) }) }}
        </span>
      </div>
    </label>
  </div>
</template>

<script>
import { getCostWithGst } from '@/assets/js/order-helper'
import MenuSectionMeal from '@/components/MenuSectionMeal'
import { mapGetters } from 'vuex'

export default {
  name: 'add-on-add-ons',
  components: {
    MenuSectionMeal
  },
  props: {
    addOn: {
      type: Object,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    selectedAddOns: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getActiveOrder'
    ]),
    selectedDate() {
      return this.getActiveOrder && this.getActiveOrder.selectedDate
    }
  },
  methods: {
    addOnAddOnWithMeal(addOnAddOn) {
      return {
        ...addOnAddOn,
        meal: addOnAddOn.item
      }
    },
    getCostWithGst(price) {
      return getCostWithGst(price, this.selectedDate)
    },
    isAddOnAddOnSelected(addOnAddOn) {
      return !!this.selectedAddOns.find((addOn) => {
        return addOn.itemType === addOnAddOn.itemType && addOn.itemId === addOnAddOn.itemId
      })
    },
    onSelectAddOnAddOn(addOn, event) {
      this.$emit('change', addOn, event.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.AddOnAddOns {
  @media #{$tablet-down} {
    margin-top: $space-l;
  }
  .AddOnAddOnsTitle {
    margin-bottom: $space-xxs;
    @extend %small;
    font-weight: bold;
    color: $gray-dark;
    text-transform: uppercase;
  }
  .AddOnAddOn {
    display: block;
    position: relative;

    &:not(.Disabled) {
      cursor: pointer;
    }
    &.Disabled .AddOnAddOnMeal {
      opacity: 0.5;
    }
    &:not(:last-child) {
      margin-bottom: $space-l;
    }
    .SelectAddOnAddOn {
      position: absolute;
      top: 1px;
    }
    .AddOnAddOnMeal {
      margin-left: $space-l;
    }
    /deep/ .MenuSectionMealByline {
      margin-bottom: $space-xxs;
    }
  }
}
</style>
