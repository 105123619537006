<template>
  <grid-row
    class="FulfilmentInfo"
    :class="{'FourColumns': showSetupAndTeardownTime}"
    :keepColumnWidth="true">
    <grid-column :class="{'IsolatedColumn': showSetupAndTeardownTime}">
      <div class="FulfilmentInfoItem">
        <img src="@/assets/images/icons/icon-clock.svg">
        <span>
          {{ $t('fulfilmentInfo.leadTimeLabel') }}
        </span>
        <div v-html="$t('fulfilmentInfo.leadTime')"/>
      </div>
    </grid-column>
    <grid-column
      v-if="showSetupAndTeardownTime">
      <div class="FulfilmentInfoItem">
        <img src="@/assets/images/icons/icon-dish.svg">
        <span>
          {{ $t('fulfilmentInfo.setupTimeLabel') }}
        </span>
        <div v-html="$t('fulfilmentInfo.setupTime')"/>
      </div>
    </grid-column>
    <grid-column>
      <div class="FulfilmentInfoItem">
        <img src="@/assets/images/icons/icon-wine-glass.svg">
        <span>
          {{ $t('fulfilmentInfo.servingTimeLabel') }}
        </span>
        <div v-html="servingTimeLabel"/>
      </div>
    </grid-column>
    <grid-column
      v-if="showSetupAndTeardownTime">
      <div class="FulfilmentInfoItem">
        <img src="@/assets/images/icons/icon-clock.svg">
        <span>
          {{ $t('fulfilmentInfo.teardownTimeLabel') }}
        </span>
        <div v-html="$t('fulfilmentInfo.teardownTime')"/>
      </div>
    </grid-column>
  </grid-row>
</template>

<script>
import GridColumn from '@/components/common/GridColumn'
import GridRow from '@/components/common/GridRow'

export default {
  name: 'fulfilment-info',
  components: {
    GridColumn,
    GridRow
  },
  props: {
    earliestTimeLabel: {
      type: String,
      default: null
    },
    latestTimeLabel: {
      type: String,
      default: null
    },
    availableTimeRanges: {
      type: Array,
      default: () => []
    },
    showSetupAndTeardownTime: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    servingTimeLabel() {
      if (this.availableTimeRanges) {
        switch (this.availableTimeRanges.length) {
          case 2:
            const lunchTimeRange = this.availableTimeRanges[0]
            const dinnerTimeRange = this.availableTimeRanges[1]
            return this.$t('fulfilmentInfo.servingTimeRanges', {
              earliestLunchTime: this.$momenttz(lunchTimeRange[0]).format('h:mmA'),
              latestLunchTime: this.$momenttz(lunchTimeRange[1]).format('h:mmA'),
              earliestDinnerTime: this.$momenttz(dinnerTimeRange[0]).format('h:mmA'),
              latestDinnerTime: this.$momenttz(dinnerTimeRange[1]).format('h:mmA')
            })
          case 3:
            return this.$t('fulfilmentInfo.threeServingTimeRanges', {
              earliestTimeRange1: this.$momenttz(this.availableTimeRanges[0][0]).format('h:mmA'),
              latestTimeRange1: this.$momenttz(this.availableTimeRanges[0][1]).format('h:mmA'),
              earliestTimeRange2: this.$momenttz(this.availableTimeRanges[1][0]).format('h:mmA'),
              latestTimeRange2: this.$momenttz(this.availableTimeRanges[1][1]).format('h:mmA'),
              earliestTimeRange3: this.$momenttz(this.availableTimeRanges[2][0]).format('h:mmA'),
              latestTimeRange3: this.$momenttz(this.availableTimeRanges[2][1]).format('h:mmA')
            })
        }
      }
      return this.$t('fulfilmentInfo.servingTime', { earliestTime: this.earliestTimeLabel, latestTime: this.latestTimeLabel })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.FulfilmentInfo {
  margin-left: -1rem;
  margin-right: -1rem;

  @media only screen and #{$tablet-down} {
    margin-left: -$space-xs;
    margin-right: -$space-xs;
  }
  /deep/ .GridColumn {
    width: 33.3333% !important;

    @media #{$tablet-down} {
      &.IsolatedColumn {
        width: 100% !important;
        margin-bottom: $space-m;
      }
    }
  }
  &.FourColumns /deep/ .GridColumn {
    width: 25% !important;

    @media #{$tablet-down} {
      width: 33.3333% !important;

      &.IsolatedColumn {
        width: 100% !important;
        margin-bottom: $space-m;
      }
    }
  }
  .FulfilmentInfoItem {
    box-sizing: border-box;
    max-width: 150px;
    padding: 0 $space-m;
    @extend %power-center;
    @extend %body;

    span {
      @extend %bold_weight;
    }
    @media only screen and #{$tablet-down} {
      padding: 0;
      text-align: left;
    }
    img {
      display: block;
      margin: 0 auto $space-xxs;

      @media only screen and #{$tablet-down} {
        margin: 0 0 $space-xxs 0;
      }
    }
  }
}
</style>
