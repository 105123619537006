<template>
  <div class="SidePanelFooter">
    <card>
      <template v-if="!floating">
        <orders-total
          v-if="total"
          :total="total"
          :totalWithGst="totalWithGst"
          :leftColumnWidth="60"/>
        <div class="Divider"/>
      </template>
      <div
        v-for="i in (floating ? 1 : 2)"
        :key="`Actions_${i}`"
        class="Actions"
        :class="{
          'Ghost': i === 2,
          'Fixed': i === 2 && fixed
        }">
        <div
          class="AddOrderButton Button--text Button--text-primary"
          @click="addOrder">
          {{ $t('sidePanel.addOrderLabel') }}
        </div>
        <div
          class="CheckoutButton Button Button--primary Button--full"
          :disabled="!timeSlotsAvailabilityLoaded"
          @click="timeSlotsAvailabilityLoaded ? checkout() : null">
          {{ timeSlotsAvailabilityLoaded ? $t('sidePanel.checkoutLabel') : $t('common.loading') }}
          <template v-if="(i === 2 || floating) && timeSlotsAvailabilityLoaded">
            - {{ $t('common.price', { price: totalWithGst }) }}
          </template>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCostWithGst, getTotal } from '@/assets/js/order-helper'
import Card from '@/components/common/Card'
import OrdersTotal from '@/components/OrdersTotal'

export default {
  name: 'side-panel-footer',
  components: {
    Card,
    OrdersTotal
  },
  props: {
    orders: {
      type: Array,
      required: true
    },
    floating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fixed: false
    }
  },
  computed: {
    ...mapGetters([
      'getOrders'
    ]),
    total() {
      return parseFloat(getTotal(this.getOrders)).toFixed(2)
    },
    totalWithGst() {
      return this.getOrders.reduce((accumulator, order) => {
        return accumulator + parseFloat(getCostWithGst(parseFloat(getTotal([order])), order.selectedDate))
      }, 0).toFixed(2)
    },
    timeSlotsAvailabilityLoaded() {
      const timeSlotsAvailabilityArray = this.orders.map((order) => order.timeSlotsAvailability)
      return timeSlotsAvailabilityArray.indexOf(null) === -1
    }
  },
  created() {
    if (!this.floating) {
      this.addWatchScroll()
    }
  },
  methods: {
    addWatchScroll() {
      window.removeEventListener('scroll', this.watchScroll)
      window.addEventListener('scroll', this.watchScroll)
    },
    watchScroll() {
      const sidePanelFooter = document.querySelector('.SidePanelFooter')
      if (sidePanelFooter) {
        this.fixed = window.scrollY > sidePanelFooter.offsetTop + sidePanelFooter.offsetHeight
        const pageFooter = document.querySelector('.PageFooter')
        if (pageFooter) {
          pageFooter.style.marginBottom = this.fixed && window.innerWidth <= 768 ? '110px' : ''
        }
      }
    },
    addOrder() {
      this.$emit('addOrder')
    },
    checkout() {
      this.$emit('checkout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.SidePanelFooter {
  position: relative;
  width: 100%;
  margin-top: $space-m;

  @media #{$tablet-down} {
    .Button--full {
      max-width: 100%;
    }
  }

  /deep/ {
    .CardContent {
      padding: 0;
    }
    .OrdersTotal {
      padding: $space-m;
    }
    .Divider {
      margin: 0 $space-m;
      height: 1px;
      background-color: $gray-lighter;
    }
    .Actions {
      padding: $space-m;

      .AddOrderButton {
        margin-bottom: $space-m;
        @extend %small;
        text-align: center;
      }
      &.Ghost {
        display: none;
      }
      &.Fixed {
        @media #{$tablet-down} {
          display: block;
          position: fixed;
          z-index: 100;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: $white;
          box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
